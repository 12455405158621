import pt from 'prop-types';

export const transaction = pt.shape({
    automatonId: pt.oneOf([pt.number, pt.string]).isRequired,
    automatonName: pt.string.isRequired,
    cardPayed: pt.number.isRequired,
    cardRefill: pt.number.isRequired,
    cashPayed: pt.number.isRequired,
    overallMoney: pt.number.isRequired,
    water: pt.number.isRequired,
});

export const automaton = pt.shape({
    id: pt.number.isRequired,
    companyId: pt.number.isRequired,
    simId: pt.string.isRequired,
    name: pt.string.isRequired,
    address: pt.string.isRequired,
    state: pt.shape({
        cash: pt.number.isRequired,
        water: pt.number.isRequired,
        currIndoorTemp: pt.number.isRequired,
        currBanknoteTemp: pt.number.isRequired,
    }),
    settings: pt.shape({
        indoorTemp: pt.number.isRequired,
        banknoteTemp: pt.number.isRequired,
        impulsePerLitre: pt.number.isRequired,
        pricePerLitre: pt.number.isRequired
    }),
    bound: pt.bool.isRequired
});

export const event = pt.shape({
    id: pt.number.isRequired,
    compadnyId: pt.number,
    type: pt.string.isRequired,
    automatonId: pt.number.isRequired,
    resolved: pt.bool.isRequired,
    date: pt.string.isRequired,
    description: pt.shape({
        code: pt.number.isRequired,
        text: pt.string.isRequired,
    }).isRequired,
    automaton,
});
