import { put, call, takeLatest, select } from 'redux-saga/effects';
import { transactions } from 'api';

import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* createAutomatonHandler() {
    try {
        yield put(ACTION.createAutomatonLoading());

        // const res = yield call(settings.createAutomaton);
        const res = [];

        yield put(ACTION.createAutomatonSuccess(res.data));
    } catch (err) {
        yield put(ACTION.createAutomatonError(err.message));
        console.error('createAutomatonHandler', err);
    }
}

export default [
    takeLatest(TYPE.CREATE_AUTOMATON, createAutomatonHandler),
];
