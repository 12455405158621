import React, { Children, useState } from 'react';
import pt from 'prop-types';
import { NavLink } from 'react-router-dom';

import './MenuItem.scss';

const MenuItem = props => {
    const { text, to, children: propsChildren, onClick } = props;
    const children = Children.toArray(propsChildren);
    const withDropDown = children.length > 0;

    const [ddVisible, setDdVisibility] = useState(false);

    const handleMouseLeave = () => {
        setDdVisibility(false);
    };

    const handleMouseEnter = () => {
        setDdVisibility(true);
    };

    const handleMenuItemClick = (e) => {
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    };

    return (
        <div
            className="menu-item"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <NavLink
                className="target"
                to={to}
                onClick={handleMenuItemClick}
            >
                {text}
            </NavLink>
            {withDropDown && (
                <div className="dropdown-wrapper" style={{ visibility: ddVisible ? 'visible' : 'hidden' }}>
                    <div className="dropdown">
                        {children.map(child => React.cloneElement(
                            child,
                            { to: `${to}${child.props.to}` }
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

MenuItem.Item = ({ to, text, onClick }) => {
    const handleSubmenuItemClick = (e) => {
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    };

    return (
        <NavLink className="dropdown-item" to={to} onClick={handleSubmenuItemClick}>
            {text}
        </NavLink>
    );
};

MenuItem.propTypes = {
    text: pt.string.isRequired,
    to: pt.string.isRequired,
    onClick: pt.func,
    children: pt.node,
};
MenuItem.defaultProps = { children: [], onClick: undefined };

MenuItem.Item.propTypes = {
    text: pt.string.isRequired,
    to: pt.string,
    onClick: pt.func
};
MenuItem.Item.defaultProps = { onClick: undefined, to: undefined };

export default MenuItem;
