import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/fops_admin/actions';
import Fops from './Fops';

const getFilteredAdminFops = state => {
    return state.adminFops.data;
};

const enhancers = [
    connect(
        state => ({
            fops: state.adminFops,
            filteredFops: getFilteredAdminFops(state)
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Fops);
