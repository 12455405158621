import { handleActions } from 'redux-actions';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        [TYPE.LOGIN_PENDING]: (state) => ({
            ...state,
            pending: true
        }),
        [TYPE.LOGIN_ERROR]: (state) => ({
            ...state,
            isAuthenticated: false,
            pending: false
        }),
        [TYPE.LOGIN_SUCCESS]: (state) => ({
            ...state,
            isAuthenticated: true,
            pending: false
        }),
        [TYPE.LOGOUT_SUCCESS]: (state) => ({
            ...state,
            isAuthenticated: false
        }),
        [TYPE.RESET_LOGIN]: (state) => ({
            ...state,
            pending: false
        }),
    },
    {
        isAuthenticated: false,
        pending: false
    }
);

export default reducer;
