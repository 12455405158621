import { handleActions } from 'redux-actions';
import { TYPE } from './actions';

const reducer = handleActions(
    {
        // FETCH_CLIENTS
        [TYPE.FETCH_CLIENTS_LOADING]: (state) => {
            return {
                ...state,
                loading: true
            };
        },
        [TYPE.FETCH_CLIENTS_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        },
        [TYPE.FETCH_CLIENTS_ERROR]: (state, action) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        },
        [TYPE.PREPARE_FILTER_SUCCESS]: (state, action) => {
            return {
                ...state,
                filterValues: action.payload
            };
        },
    },
    {
        loading: false,
        data: [],
        filterValues: [],
        error: null,
    }
);

export default reducer;
