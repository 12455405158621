import React from 'react';

import { getTime } from 'utils/helpers';
import { AutomatonNameCell } from 'components/shared';

export default [
    {
        title: 'ID автомата',
        dataIndex: 'automatonId',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Назва автомата',
        dataIndex: 'automaton',
        key: 'automaton.name',
        render: automaton => <AutomatonNameCell automaton={automaton} />,
    },
    {
        title: 'Продано води, л',
        dataIndex: 'water',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Оплата готівкою, грн',
        dataIndex: 'cashPayed',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Оплата картою, грн',
        dataIndex: 'cardPayed',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Поповнення карти, грн',
        dataIndex: 'cardRefill',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Сумма, грн',
        dataIndex: 'overallMoney',
        className: 'sum',
        render: text => ({
            children: <div className="cell">{text}</div>
        })
    }
];
