import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import Filters from './Filters';

const enhancers = [
    reduxForm({ form: 'automatonsFilters' })
];

export default compose(...enhancers)(Filters);
