import axios from 'axios';

import { createFormData, showMessage } from 'utils/helpers';
import authorisationService from 'services/authorization';
import { clientId, clientSecret } from './config';

const authRequest = axios.create({
    headers: {
        Authorization: `Basic ${window.btoa(`${clientId}:${clientSecret}`)}`,
    }
});

authRequest.interceptors.response.use(
    response => response,
    error => {
        const { status, data } = error.response || {};
        if (status === 400 && data.error === 'invalid_grant') {
            showMessage({
                message: 'Authorization failed',
                description: `Cause: ${data.error_description}`,
            });
        }

        return Promise.reject(error);
    }
);

export const makeRefresh = async refresh_token => {
    try {
        const formData = createFormData({
            grant_type: 'refresh_token',
            refresh_token
        });

        const { data } = await authRequest({ url: 'oauth/token', method: 'post', data: formData });

        localStorage.setItem('token', data.access_token);

        // refresh token will have the same expiration period so no need to override
        // localStorage.setItem('refresh_token', data.refresh_token);
        authorisationService.update();

        return {
            data
        };
    } catch (err) {
        return {
            err
        };
    }
};

export default authRequest;
