import { put, call, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { showMessage, getFilterParams } from 'utils/helpers';

import { request } from 'api';
import { ACTION as AUTOMATON_ACTION } from '../automatons/actions';
import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* fetchAdminAutomatonsHandler() {
    try {
        const filters = yield select(selectors.filters);

        yield put(ACTION.fetchAutomatonsLoading());

        const res = yield call(request, {
            url: 'automatons',
            params: getFilterParams(filters)
        });

        const sorted = _.sortBy(res.data, 'id')

        yield put(ACTION.fetchAutomatonsSuccess(sorted));
    } catch (err) {
        yield put(ACTION.fetchAutomatonsError(err));
        console.error('fetchAdminAutomatonsHandler', err);
    }
}

function* editAutomatonHandler({ payload }) {
    const automaton = yield select(selectors.getAutomatonById(payload));

    if (automaton) {
        yield put(ACTION.toggleEditModal({ active: true, data: automaton }));
    } else {
        throw new Error('Edit: Choosen automaton not found.');
    }
}

function* openAutomatonHandler({ payload }) {
    const automaton = yield select(selectors.getAutomatonById(payload));

    if (automaton) {
        yield put(ACTION.toggleOpenModal({ active: true, data: automaton }));
    } else {
        throw new Error('Edit: Choosen automaton not found.');
    }
}

function* submitEditHandler({ payload: edittedValues }) {
    try {
        const newFields = {};

        const editedAutomaton = yield select(selectors.getModalData('editAutomaton'));
        if (!editedAutomaton || !edittedValues) {
            throw Error('Упс... Щось пішло не так');
        }
        newFields.id = editedAutomaton.id;
        if (_.has(edittedValues, 'automatonName')) {
            _.set(newFields, 'name', edittedValues.automatonName);
        }
        if (_.has(edittedValues, 'address')) {
            _.set(newFields, 'address', edittedValues.address);
        }

        _.set(newFields, 'cityId', _.get(edittedValues, 'city') || null);
        _.set(newFields, 'masterId', _.get(edittedValues, 'master') || null);
        _.set(newFields, 'serviceCenterId', _.get(edittedValues, 'serviceCenter') || null);
        _.set(newFields, 'cashRegisterNum', _.get(edittedValues, 'cashRegisterNum') || null);

        if (_.has(edittedValues, 'price')) {
            _.set(newFields, 'settings.pricePerLitre', edittedValues.price);
        }
        if (_.has(edittedValues, 'tempInside')) {
            _.set(newFields, 'settings.indoorTemp', edittedValues.tempInside);
        }
        if (_.has(edittedValues, 'tempCash')) {
            _.set(newFields, 'settings.banknoteTemp', edittedValues.tempCash);
        }
        if (_.has(edittedValues, 'impulseCount')) {
            _.set(newFields, 'settings.impulsePerLitre', edittedValues.impulseCount);
        }

        yield call(request, {
            method: 'PATCH',
            url: 'automatons',
            data: newFields,
            rawJson: true
        });

        yield put(ACTION.toggleEditModal({ active: false, data: {} }));
        yield put(ACTION.fetchAutomatons());
        yield put(AUTOMATON_ACTION.prepareFilter());

        showMessage({ message: 'Відредаговано успішною', type: 'success' });
    } catch (err) {
        showMessage({ message: 'Не вдалося редагувати', type: 'error' });
        console.error('submitEditHandler', err);
    }
}

function* submitOpenHandler({ payload = {} }) {
    try {
        const openedAutomaton = yield select(selectors.getModalData('openAutomaton'));
        const id = openedAutomaton && openedAutomaton.id;
        if (!id) {
            throw new Error('Id is not provided');
        }
        debugger;
        yield call(request, {
            method: 'POST',
            url: `/automatons/${id}/open-for-service`
        });

        yield put(ACTION.toggleOpenModal({ active: false, data: {} }));
        yield* fetchAdminAutomatonsHandler();
    } catch (err) {
        showMessage({ message: 'Не вдалося відкрити автомат', type: 'error' });
        console.error('openSubmitHandler', err);
    }
}

function* setPriceSubmitHandler({ payload = {} }) {
    try {
        const requestBody = {};
        if (_.has(payload, 'pricePerLitr')) {
            requestBody.price = payload.pricePerLitr;
        } else {
            throw new Error('Price is missing.');
        }

        if (_.has(payload, 'automatons') || _.has(payload, 'cities')) {
            if (_.has(payload, 'automatons')) {
                requestBody.automatonIds = payload.automatons;
            }
            if (_.has(payload, 'cities')) {
                requestBody.cityIds = payload.cities;
            }
        } else {
            throw new Error('nor cities nor automatons aren\'t choosen');
        }

        yield call(request, {
            method: 'POST',
            url: 'account-admin/prices/set',
            data: requestBody
        });

        yield put(ACTION.toggleSetPriceModal({ active: false, data: {} }));
        yield* fetchAdminAutomatonsHandler();
    } catch (err) {
        showMessage({ message: 'Не вдалося встановити ціну', type: 'error' });
        console.error('setPriceSubmitHandler', err);
    }
}

function* setMasterSubmitHandler({ payload = {} }) {
    try {
        const requestBody = {};

        if (_.has(payload, 'automatons')) {
            requestBody.automatonIds = payload.automatons;
        }
        if (_.has(payload, 'master')) {
            requestBody.masterId  = payload.master;
        }

        yield call(request, {
            method: 'POST',
            url: 'automatons/set-master',
            data: requestBody
        });

        yield put(ACTION.toggleSetMasterModal({ active: false, data: {} }));
        yield* fetchAdminAutomatonsHandler();
    } catch (err) {
        showMessage({ message: 'Не вдалося встановити майстра', type: 'error' });
        console.error('setMasterSubmitHandler', err);
    }
}

function* setTemperatureSubmitHandler({ payload = {} }) {
    try {
        const requestBody = {};

        if (_.has(payload, 'automatons')) {
            requestBody.automatonIds = payload.automatons;
        }
        if (_.has(payload, 'cities')) {
            requestBody.cityIds = payload.cities;
        }
        if (_.has(payload, 'tempCash')) {
            requestBody.banknoteTemp  = payload.tempCash;
        }
        if (_.has(payload, 'tempInside')) {
            requestBody.indoorTemp  = payload.tempInside;
        }

        yield call(request, {
            method: 'POST',
            url: 'automatons/set-temperature',
            data: requestBody
        });

        yield put(ACTION.toggleSetTemperatureModal({ active: false, data: {} }));
        yield* fetchAdminAutomatonsHandler();
    } catch (err) {
        showMessage({ message: 'Не вдалося встановити температуру', type: 'error' });
        console.error('setTemperatureSubmitHandler', err);
    }
}

//  Checkbox select
function* toggleAll({ payload: value }) {
    const adminAutomatons = yield select(selectors.adminAutomatons);
    adminAutomatons.forEach(a => a.selected = !!value);
    yield put(ACTION.updateAutomatons({ data: adminAutomatons }));
    yield* calculateSelectedAll();
}

function* toggleById({ payload: { id, value } }) {
    const adminAutomatons = yield select(selectors.adminAutomatons);
    const choosen = adminAutomatons.find(a => a.id === id);
    if (choosen) {
        choosen.selected = !!value
    }
    yield put(ACTION.updateAutomatons({ data: adminAutomatons }));
    yield* calculateSelectedAll();
}

function* calculateSelectedAll() {
    const adminAutomatons = yield select(selectors.adminAutomatons);
    const selected = adminAutomatons.every(a => !!a.selected);
    yield put(ACTION.setSelectedAll({ selected }));
}


export default [
    takeLatest(TYPE.FETCH_AUTOMATONS, fetchAdminAutomatonsHandler),
    takeLatest(TYPE.EDIT, editAutomatonHandler),
    takeLatest(TYPE.OPEN, openAutomatonHandler),
    takeLatest(TYPE.SUBMIT_EDIT, submitEditHandler),
    takeLatest(TYPE.SUBMIT_OPEN, submitOpenHandler),
    takeLatest(TYPE.SUBMIT_SET_PRICE_MODAL, setPriceSubmitHandler),
    takeLatest(TYPE.SUBMIT_SET_MASTER_MODAL, setMasterSubmitHandler),
    takeLatest(TYPE.SUBMIT_SET_TEMPERATURE_MODAL, setTemperatureSubmitHandler),
    takeLatest(TYPE.TOGGLE_ALL, toggleAll),
    takeLatest(TYPE.TOGGLE_BY_ID, toggleById),
];
