import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import ukraineLocale from 'antd/es/locale/uk_UA';

import configureStore from 'app/configureStore';
import AuthorizationService from 'services/authorization';
import AppNavigation from './navigation';

import './styles/global.scss';

const { store, persistor, history } = configureStore();

const App = () => {
    const [value, setValue] = React.useState(false);
    const rerender = () => {
        setValue(!value);
    };
    AuthorizationService.registerTrigger(rerender);

    return (
        <AuthorizationService.Provider>
            <StoreProvider store={store}>
                <ConnectedRouter history={history}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ConfigProvider locale={ukraineLocale}>
                            <AppNavigation />
                        </ConfigProvider>
                    </PersistGate>
                </ConnectedRouter>
            </StoreProvider>
        </AuthorizationService.Provider>
    );
};

export { store, persistor };

export default App;
