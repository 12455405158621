import get from 'lodash/get';

export const getFilters = state => state.form.adminFopsFilters;

export const getModalData = modalName => state => get(state, `adminFops.modals.${modalName}.data`, {});

export const getFopById = id => state => {
    const cities = get(state, 'adminFops.data', []);
    return cities.find((a) => a.id === id);
};

export const isNewModal = modalName => state => {
    return get(state, `adminFops.modals.${modalName}.data.new`, false);
};
