import { put, call, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { showMessage } from 'utils/helpers';

import { request } from 'api';
import { TYPE, ACTION } from './actions';
import { ACTION as MASTER_ACTION } from '../masters/actions';
import * as selectors from './selectors';

function* fetchUsersHandler() {
    try {
        yield put(ACTION.fetchUsersLoading());

        const res = yield call(request, 'account-admin/users');

        yield put(ACTION.fetchUsersSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchUsersError(err));
        console.error('fetchUsersHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'account-admin/users');
        const filtersData = _.get(res, 'data', []);
        yield put(ACTION.prepareFilterSuccess(filtersData));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

function* editUserHandler({ payload }) {
    try {
        if (payload.new) {
            yield put(ACTION.toggleEditModal({ active: true, data: payload }));
        } else {
            const user = yield select(selectors.getUserById(payload.id));

            if (!user) {
                throw new Error('Edit: Choosen city not found.');
            } else {
                yield put(ACTION.toggleEditModal({ active: true, data: user }));
            }
        }
    } catch (err) {
        console.error('editUserHandler', err);
    }
}

/**
 *
  "firstName": "string",
  "username": "string",
  "password": "string",
  "companyId": 0,
  "userType": "SUPER_ADMIN",
  "enabled": true,
  "isAccountNonExpired": null
  "isAccountNonLocked": null
  "isCredentialsNonExpired": null
 */

function* submitEditHandler({ payload }) {
    try {
        const isNew = yield select(selectors.isNewModal('editUser'));
        let body = {
            firstName: _.get(payload, 'fullName'),
            password: _.get(payload, 'userPass'),
            userType: _.get(payload, 'userType'),
            enabled: _.get(payload, 'userActive'),
        };

        body = _.pickBy(body, _.identity);

        if (isNew) {
            if (_.has(payload, 'userLogin')) {
                body.username = payload.userLogin;
            }

            yield call(request, {
                method: 'POST',
                url: 'account-admin/users',
                data: body
            });
            showMessage({ message: 'Створено користувача:', description: payload.userLogin, type: 'success' });
        } else {
            const editedUser = yield select(selectors.getModalData('editUser'));
            if (!editedUser) {
                throw new Error('Failed to get modal data!');
            }
            body.id = editedUser.id;
            body.enabled = _.get(payload, 'userActive');

            yield call(request, {
                method: 'PUT',
                url: 'account-admin/users',
                data: body
            });
            showMessage({ message: 'Відредаговано успішною', type: 'success' });
        }

        yield put(ACTION.toggleEditModal({ active: false, data: {} }));
        yield put(ACTION.prepareFilter());
        yield put(ACTION.fetchUsers());
        yield put(MASTER_ACTION.prepareFilter());
    } catch (err) {
        console.error('submitEditHandler', err);
    }
}

export default [
    takeLatest(TYPE.FETCH_USERS, fetchUsersHandler),
    takeLatest(TYPE.EDIT, editUserHandler),
    takeLatest(TYPE.SUBMIT_EDIT, submitEditHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
];
