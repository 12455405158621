import { constants, actions } from 'ducks-helpers';

//  SUFFIXES
// [
//   'LOADING',
//   'PENDING',
//   'SUCCESS',
//   'ERROR',
//   'FAILED',
//   'CANCELED'
// ]

export const TYPE = constants('transactions/services', [
    '~FETCH_SERVICES_TRANSACTIONS',
    'EXPORT_TO_EXCEL'
]);

export const ACTION = actions(TYPE);
