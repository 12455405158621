import React from 'react';

import { getTime, convertTransactionToType, convertTaxTransactionToLink } from 'utils/helpers';
import { AutomatonNameCell } from 'components/shared';

export default [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        fixed: 'left',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'ID автомата',
        dataIndex: 'automaton.id',
        key: 'automatonId',
        width: 100,
        fixed: 'left',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Каса',
        dataIndex: 'cashDesk',
        key: 'cashDesk.cashRegisterNum',
        width: 300,
        fixed: 'left',
        render: cd => <div className="cell">{cd?.cashRegisterNum} <br/> {cd?.name}</div>,
    },

    {
        title: 'Назва автомата',
        dataIndex: 'automaton',
        key: 'automaton.name',
        // fixed: 'left',
        // width: 100,
        render: automaton => <AutomatonNameCell automaton={automaton} />,
    },
    {
        title: 'Сума, грн',
        dataIndex: 'money',
        render: text => <div className="cell">{text} грн.</div>,
    },
    {
        title: 'Кількість води',
        dataIndex: 'water',
        key: 'water',
        render: text => <div className="cell">{text} л.</div>,
    },
    {
        title: 'Тип транзакції',
        // dataIndex: '',
        key: 'type',
        render: transaction => <div className="cell">{convertTransactionToType(transaction)}</div>,
    },
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        render: timestamp => {
            const [date, time] = getTime(timestamp);
            return <div className="cell">{date} {time}</div>
        }
    },
    {
        title: 'ФОП',
        dataIndex: 'fop.name',
        key: 'fop',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Касир',
        dataIndex: 'cashier.name',
        key: 'cashier.id',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Чек',
        dataIndex: '',
        render: tr => <div className="cell">{
            convertTaxTransactionToLink(tr) && <a href={convertTaxTransactionToLink(tr)} target={'_blank'}>Отримати</a>
        }
        </div>,
    },
];
