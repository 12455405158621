import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/transactions/byAccounts/actions';
import ByAccountTransactions from './ByAccountTransactions';

const getFilteredByAccounts = state => {
    return state.transactions.byAccounts.data;
};

const enhancers = [
    connect(
        state => ({
            byAccounts: state.transactions.byAccounts,
            filteredByAccounts: getFilteredByAccounts(state),
            automatonFilterValues: state.automatons.filterValues,
            clientsFilterValues: state.clients.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(ByAccountTransactions);
