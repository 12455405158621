import { put, call, takeLatest, select } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import _ from 'lodash';

import { showMessage, getFilterParams } from 'utils/helpers';
import { request, pagableRequest } from 'api';
import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* fetchPricesHandler({ payload = {} }) {
    try {
        const filters = yield select(selectors.filters);

        yield put(ACTION.fetchPricesLoading());
        
        const res = yield call(pagableRequest(payload), {
            url: '/account-admin/prices/history',
            params: getFilterParams(filters)
        });

        yield put(ACTION.fetchPricesSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchPricesError(err));
        console.error('fetchPricesHandler', err);
    }
}

function* exportToExcel() {
    try {
        const filters = yield select(selectors.filters);
        showMessage({ type: 'warning', message: 'Почалося завантаження звіту - це може зайняти деякий час.' });

        const res = yield call(request, {
            responseType: 'arraybuffer',
            url: '/account-admin/prices/history/export',
            params: getFilterParams(filters),
        });

        const contentDisposition = _.get(res, 'headers.content-disposition', 'filename=report.xls');
        const [, filename] = contentDisposition.match(/filename=(.*)/);

        const blob = new Blob([res.data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    } catch (err) {
        console.log(err);
    }
}

export default [
    takeLatest(TYPE.FETCH_PRICES, fetchPricesHandler),
    takeLatest(TYPE.EXPORT_TO_EXCEL, exportToExcel),
];
