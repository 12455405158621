import balances from './balances/sagas';
import cashCollection from './cash_collection/sagas';
import remainders from './remainders/sagas';
import transactions from './transactions/sagas';
import prices from './prices/sagas';

export default [
    ...balances,
    ...cashCollection,
    ...remainders,
    ...transactions,
    ...prices
];
