/* eslint-disable no-confusing-arrow */
import React, { useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { Field } from 'redux-form';

import {
    prepareAutomatonFilter,
    prepareCityFilter,
    prepareMasterFilter,
    prepareUserFilter
} from 'utils/by-demand-data-fetch';
import { pricesHistoryColumns } from 'utils/tablesConfig';
import { Page, ReduxInputs, Filters, Table } from 'components/shared'

import './Prices.scss';

const Prices = (props) => {
    const { actions, prices, filteredPrices, automatonFilterValues, mastersFilterValues, citiesFilterValues, userFilterValues } = props;
    const { fetchPrices, exportToExcel } = actions;
    const { loading } = prices;

    useEffect(() => {
        fetchPrices();
        prepareAutomatonFilter();
        prepareMasterFilter();
        prepareCityFilter();
        prepareUserFilter();
    }, []);

    return (
        <Page className="prices-admin wide-table">
            <div className="container">
                <Filters formId="reportsPricesFilter" initialValues={{ cities: [], automatons: [], masters: [] }}>
                    <Row type="flex" justify="space-between">
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="masters"
                                id="report-transactions-masters"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Майстри"
                                mode="multiple"
                            >
                                {mastersFilterValues.map(master => (
                                    <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                        {master.firstName} {master.lastName}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="users"
                                id="transactions-by-account-users"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Користувачі"
                                mode="multiple"
                            >
                                {userFilterValues.map(user => <ReduxInputs.Select.Option key={user.id} value={user.id}>{user.firstName}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-payments-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="cities"
                                id="report-transactions-city"
                                component={ReduxInputs.Select}
                                placeholder="Міста"
                                inputSpan={24}
                                mode="multiple"
                            >
                                {citiesFilterValues.map(city => (
                                    <ReduxInputs.Select.Option key={city.id} value={city.id}>
                                        {city.name}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="price"
                                id="transactions-report-clients"
                                component={ReduxInputs.Text}
                                inputSpan={24}
                                placeholder="Ціна"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Field
                            name="dateRange"
                            component={ReduxInputs.RangePicker}
                            placeholder={['Від', 'До']}
                            id="transactions-payments-paymentsDateRange"
                            onFocus={e => e.preventDefault()}
                            onBlur={e => e.preventDefault()}
                            inputSpan={24}
                        />
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchPrices}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>

            <Table
                rowKey="id"
                data={filteredPrices}
                loading={loading}
                onFetch={fetchPrices}
                paginationPath="reports.prices.pagination"
                columns={pricesHistoryColumns}
            />
        </Page>
    );
};

export default Prices;
