import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Auth from 'navigation/Auth';
import Application from 'navigation/Application';

const AppNavigation = React.memo(({ isAuthenticated }) => {
    return (
        <div className="root">
            <Switch>
                <Route path="/" component={isAuthenticated ? Application : Auth} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </div>
    );
});

export default connect(
    store => ({
        isAuthenticated: store.auth.isAuthenticated,
    })
)(AppNavigation);
