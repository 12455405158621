import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from 'navigation';

import PaymentsTransactions from './PaymentsTransactions';
import ByAccountTransactions from './ByAccountTransactions';
import CollectionsTransactions from './CollectionsTransactions';
import ServicesTransactions from './ServicesTransactions';

export default () => {
    return (
        <Switch>
            <Route exact path="/transactions" render={() => <Redirect to="/transactions/payments" />} />
            <ProtectedRoute path="/transactions/payments" component={PaymentsTransactions} />
            <ProtectedRoute path="/transactions/by-accounts" component={ByAccountTransactions} />
            <ProtectedRoute path="/transactions/collections" component={CollectionsTransactions} />
            <ProtectedRoute path="/transactions/services" component={ServicesTransactions} />
        </Switch>
    );
};
