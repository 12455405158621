import React from 'react';

import { getTime, convertTransactionToType } from 'utils/helpers';
import { AutomatonNameCell } from 'components/shared';

export default [
    {
        title: 'ID автомата',
        dataIndex: 'automaton.id',
        key: 'automatonId',
        render: text => <div className="cell">{text}</div>,
    },
    // {
    //     title: 'Номер рахунку',
    //     dataIndex: 'clientId',
    //     key: 'clientId',
    //     render: text => <div className="cell">{text || '--'}</div>,
    // },
    {
        title: 'Назва автомата',
        dataIndex: 'automaton',
        key: 'automaton.name',
        render: automaton => <AutomatonNameCell automaton={automaton} />,
    },
    {
        title: 'Тип транзакції',
        // dataIndex: '',
        key: 'type',
        render: transaction => <div className="cell">{convertTransactionToType(transaction)}</div>,
    },
    {
        title: 'Сума, грн',
        dataIndex: 'money',
        render: text => <div className="cell">{text} грн.</div>,
    },
    {
        title: 'Кількість води',
        dataIndex: 'water',
        key: 'water',
        render: text => <div className="cell">{text} л.</div>,
    },
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        render: timestamp => {
            const [date, time] = getTime(timestamp);
            return <div className="cell">{date} {time}</div>
        }
    }
];
