/* eslint-disable react/no-unused-prop-types */
import React, { useState, useCallback } from 'react';
import pt from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Icon, Card } from 'antd';

import './Filters.scss';

const Filters = props => {
    const { className, children } = props;
    const [open, setOpen] = useState(false);

    const toggle = useCallback(() => {
        setOpen(!open);
    }, [open]);

    return (
        <section className={cn('filters-section', className, { hidden: !open })}>
            {open && (
                <div className="controls">
                    {children}
                </div>
            )}
            <div className="toggler-wrapper">
                <div className="toggler" onClick={toggle}>
                    <Icon type="filter" />
                    <span>{open ? 'Сховати' : 'Показати'} фільтри</span>
                    <Icon type={open ? 'up' : 'down'} />
                </div>
            </div>
        </section>
    );
};

Filters.propTypes = {
    formId: pt.string.isRequired,
    initialValues: pt.shape({}),
    className: pt.string,
    children: pt.oneOfType([
        pt.arrayOf(pt.node),
        pt.node
    ]).isRequired
};

Filters.defaultProps = {
    className: '',
    initialValues: {}
};

const enhanceComponent = compose(
    connect((state, props) => ({
        form: props.formId,
        initialValues: props.initialValues
    })),
    reduxForm({ asyncBlurFields: [] })
);

const EnhancedFilters = enhanceComponent(Filters);

export default EnhancedFilters;
