import React from 'react';
import pt from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Input, Button, DatePicker } from 'antd';

import { ACTION } from 'app/ducks/auth/actions';
import makeField from 'utils/redux-form/makeField';
import { formatDatePicker } from 'utils/redux-form/formatters';

import './Registration.scss';

const TextInput = makeField(Input);
const DateInput = makeField(DatePicker);

const Registration = props => {
    const register = () => {
        props.auth();
    };
    const submitForm = data => {
        console.log(data);
    };

    return (
        <Row className="registration" type="flex" justify="center">
            <Col xs={18} md={14} lg={8}>
                <form className="form" onSubmit={props.handleSubmit(submitForm)}>
                    <div className="title">Registration</div>
                    <div className="content">
                        <Field
                            label="First name"
                            name="firstName"
                            component={TextInput}
                            placeholder="Enter first name"
                            type="text"
                        />
                        <Field
                            label="Second name"
                            name="secondName"
                            component={TextInput}
                            placeholder="Enter second name"
                            type="text"
                        />
                        <Field
                            label="Phone"
                            name="phone"
                            component={TextInput}
                            placeholder="Enter phone"
                            type="tel"
                        />
                        <Field
                            label="Birthday"
                            name="birthday"
                            component={DateInput}
                            placeholder="date"
                            type="date"
                            // normalize={normalizeDatePicker}
                            format={formatDatePicker}
                        />
                        <Field
                            label="Email"
                            name="email"
                            component={TextInput}
                            placeholder="Enter email"
                            type="email"
                        />
                        <Field
                            label="Password"
                            name="password1"
                            component={TextInput}
                            placeholder="Enter password"
                            type="password"
                        />
                        <Field
                            label="Confirm password"
                            name="password2"
                            component={TextInput}
                            placeholder="Confirm password"
                            type="password"
                        />

                        <Row type="flex" justify="center">
                            <Button htmlType="submit">Submit</Button>
                        </Row>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

Registration.propTypes = {
    auth: pt.func.isRequired
};

const validate = (form) => {
    const errors = { };

    if (!form.name) {
        errors.name = 'required';
    }

    if (!form.password) {
        errors.password = 'required';
    } else if (form.password.length < 5) {
        errors.password = 'to short';
    }

    return errors;
};

export default reduxForm({ form: 'registration', validate })(
    connect(
        null,
        dispatch => ({
            auth: payload => dispatch(ACTION.auth(payload))
        })
    )(Registration)
);
