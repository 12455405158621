/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import { Icon } from 'antd';
import cn from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';

import './PrevNextControl.scss';

const PrevNextControl = ({ paginationData, dataPath, onChange }) => {
    const { page, first, last } = paginationData || {};

    const handleNextChange = useCallback(() => {
        onChange({ page: page + 1 });
    }, []);

    const handlePrevChange = useCallback(() => {
        onChange({ page: page - 1 });
    }, []);

    if (!paginationData) {
        console.error(`paginationData not found in path '${dataPath}'`);
        return <></>;
    }

    return (
        <div className="prev-next-control">
            <div className="current-page">
                Сторінка {page + 1}
            </div>
            <div
                className={cn('ctrl', 'prev', { disabled: first })}
                onClick={handlePrevChange}
            >
                <Icon type="left" />
            </div>
            <div
                className={cn('ctrl', 'next', { disabled: last })}
                onClick={handleNextChange}
            >
                <Icon type="right" />
            </div>
        </div>
    );
};

export default connect(
    (state, props) => {
        return {
            paginationData: _.get(state, props.dataPath),
        };
    }
)(PrevNextControl);
