import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createRootReducer from 'app/ducks/rootReducer';
import rootSaga from 'app/ducks/rootSaga';
import { showMessage } from 'utils/helpers';

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware({
    onError: (err) => {
        showMessage({ message: 'Unexpected error', description: err.message });
    }
});

const routerMiddleware = createRouterMiddleware(history);

// prettier-ignore
const composeEnhancers = (
    process.env.NODE_ENV === 'development'
    && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

//  **  persistor
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
};
const persistedReducer = persistReducer(persistConfig, createRootReducer(history));
//  **

export default () => {
    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware))
    );

    if (module.hot) {
        module.hot.accept('./ducks/rootReducer', () => {
            const createNextReducer = require('app/ducks/rootReducer').default;
            const nextReducerEnhanced = persistReducer(persistConfig, createNextReducer(history));

            store.replaceReducer(nextReducerEnhanced);
        });
    }

    const persistor = persistStore(store);

    sagaMiddleware.run(rootSaga);

    return {
        store,
        persistor,
        history
    };
};
