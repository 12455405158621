import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/reports/balances/actions';
import BalancesReport from './BalancesReport';

const getFilteredTransactionsReport = state => {
    return state.reports.balances.data;
};

const enhancers = [
    connect(
        state => ({
            balances: state.reports.balances,
            filteredBalances: getFilteredTransactionsReport(state),
            automatonFilterValues: state.automatons.filterValues,
            mastersFilterValues: state.masters.filterValues,
            citiesFilterValues: state.adminCities.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(BalancesReport);
