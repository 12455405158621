import * as messages from './validationMessages';

const required = value => value !== 0 && !value && messages.required;

const number = value => {
    const isNumber = Number(value) || Number(value) === 0;
    return !isNumber && messages.shouldBeNumber;
};
number.positive = value => value < 0 && messages.shouldBePositive;

const arrayRequired = value => (!Array.isArray(value) || value.length < 1) && messages.required;

export default {
    required,
    arrayRequired,
    number,
};
