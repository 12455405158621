import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/tax/actions';
import Tax from './Tax';

const getFilteredTransactions = state => {
    return state.tax.data;
};

const enhancers = [
    connect(
        state => ({
            taxTransactions: state.tax,
            filteredTaxTransactions: getFilteredTransactions(state),
            automatonFilterValues: state.automatons.filterValues,
            fopsFilterValues: state.adminFops.filterValues,
            cashDesksFilterValues: state.adminCashDesks.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Tax);
