/* eslint-disable max-len */
/* eslint-disable no-param-reassign */

import React from 'react';
import { Form } from 'antd';

const FormItem = Form.Item;

const makeField = Component => ({ input, meta, hasFeedback, label, labelSpan, inputSpan, hint, ...rest }) => {
    const hasError = meta.submitFailed && meta.invalid;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: labelSpan || 6 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: inputSpan || 18 }
        },
    };

    return (
        <FormItem
            {...formItemLayout}
            label={label}
            validateStatus={hasError ? 'error' : 'success'}
            hasFeedback={hasFeedback && hasError}
            help={(hasError && meta.error) || hint}
        >
            <Component {...input} {...rest} checked={input.value} />
        </FormItem>
    );
};

export default makeField;
