import React from 'react';
import { Empty } from 'antd';

import './NoData.scss';

const NoData = () => {
    return (
        <section className="container no-data">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </section>
    );
};

export default NoData;
