import { put, call, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { showMessage } from 'utils/helpers';

import { request } from 'api';
import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* fetchCashDesksHandler() {
    try {
        yield put(ACTION.fetchCashDesksLoading());

        const res = yield call(request, 'account-admin/cash-desk');

        yield put(ACTION.fetchCashDesksSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchCashDesksError(err));
        console.error('fetchCashDesksHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'account-admin/cash-desk');
        const filtersData = _.get(res, 'data', []);
        yield put(ACTION.prepareFilterSuccess(filtersData));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

function* editCashDeskHandler({ payload }) {
    try {
        if (payload.new) {
            yield put(ACTION.toggleEditModal({ active: true, data: payload }));
        } else {
            const cashDesk = yield select(selectors.getCashDeskById(payload.cashRegisterNum));

            if (!cashDesk) {
                throw new Error('Edit: Chosen cashDesk not found.');
            } else {
                yield put(ACTION.toggleEditModal({ active: true, data: cashDesk }));
            }
        }
    } catch (err) {
        console.error('editCashDeskHandler', err);
    }
}

function* submitEditHandler({ payload = {} }) {
    try {
        const body = {
            name: payload.name,
            cashRegisterNum: payload.cashRegisterNum,
            fopId: payload.fopId,
            cashierLogin: payload.cashierLogin,
            enabled: payload.enabled,
            licenseKey: payload.licenseKey,
        };
        const isNew = yield select(selectors.isNewModal('editCashDesk'));

        if (isNew) {
            yield call(request, {
                method: 'POST',
                url: 'account-admin/cash-desk',
                data: body
            });
            showMessage({ message: 'Створено касу:', description: payload.name, type: 'success' });
        } else {
            const editedCashDesk = yield select(selectors.getModalData('editCashDesk'));
            if (!editedCashDesk) {
                throw new Error('Failed to get modal data!');
            }
            body.cashRegisterNum = editedCashDesk.cashRegisterNum;
            yield call(request, {
                method: 'PUT',
                url: `account-admin/cash-desk`,
                data: body
            });
            showMessage({ message: 'Відредаговано успішною', type: 'success' });
        }

        yield put(ACTION.toggleEditModal({ active: false, data: {} }));
        yield put(ACTION.fetchCashDesks());
        yield put(ACTION.prepareFilter());
    } catch (err) {
        console.error('submitEditHandler', err);
    }
}

export default [
    takeLatest(TYPE.FETCH_CASH_DESKS, fetchCashDesksHandler),
    takeLatest(TYPE.EDIT, editCashDeskHandler),
    takeLatest(TYPE.SUBMIT_EDIT, submitEditHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
];
