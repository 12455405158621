import payments from './payments/sagas';
import services from './services/sagas';
import collections from './collections/sagas';
import byAccounts from './byAccounts/sagas';

export default [
    ...payments,
    ...services,
    ...collections,
    ...byAccounts
];
