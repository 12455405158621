import React, { Fragment } from 'react';
import { Icon, Checkbox, Row, Col, Tooltip } from 'antd';
import get from 'lodash/get';

import { store } from 'App';
import { ACTION } from 'app/ducks/automatons_admin/actions';
import dispatch from 'app/dispatch';

const createOnChangeHandler = (id) => (e) => {
    dispatch(ACTION.toggleById({ id, value: e.target.checked }));
};

const handleToggleAll = (e) => {
    dispatch(ACTION.toggleAll(e.target.checked));
};

export default [
    {
        onHeaderCell: (column) => {
            const state = store.getState();
            const checked = get(state, 'adminAutomatons.allSelected', false);
            return {
                children: <label htmlFor="select_all" className="cell pointer">
                    <Checkbox id="select_all" onChange={handleToggleAll} checked={checked} />
                </label>
            }
        },

        render: automaton => <label htmlFor={automaton.id} className="cell pointer">
            <Checkbox id={automaton.id} onChange={createOnChangeHandler(automaton.id)} checked={!!automaton.selected} />
        </label>,
        width: 50,
        fixed: 'left',
    },
    {
        title: 'ID',
        dataIndex: 'id',
        render: text => <div className="cell">{text}</div>,
        width: 100,
        fixed: 'left',
    },
    {
        title: 'Назва',
        dataIndex: 'name',
        render: text => <div title={text} className="cell">{text}</div>,
        width: 320,
        fixed: 'left',
    },
    {
        title: 'РРО',
        dataIndex: 'cashRegisterNum',
        render: text => <div title={text} className="cell">{text}</div>,
        width: 200,
    },
    {
        title: 'Адреса',
        dataIndex: 'address',
        render: text => <div title={text} className="cell">{text}</div>,
        width: 200,
    },
    {
        title: 'SIM ID',
        width: 200,
        dataIndex: 'simId',
        render: text => <div title={text} className="cell">{text}</div>,
    },
    {
        title: <Fragment>t <Icon type="hdd" />, &deg;C</Fragment>,
        dataIndex: 'settings.indoorTemp',
        render: text => <div className="cell">{text}</div>,
        width: 100,
    },
    {
        title: <Fragment>t <Icon type="dollar" />, &deg;C</Fragment>,
        dataIndex: 'settings.banknoteTemp',
        render: text => <div className="cell">{text}</div>,
        width: 100,
    },
    {
        title: 'імпусьсів/л',
        dataIndex: 'settings.impulsePerLitre',
        render: text => <div className="cell">{text}</div>,
        width: 150,
    },
    {
        title: 'Ціна',
        dataIndex: 'settings.pricePerLitre',
        render: text => <div className="cell">{text}</div>,
        width: 100,
    },
    {
        title: 'Місто',
        dataIndex: 'city.name',
        render: text => <div className="cell">{text || '---'}</div>,
        width: 200,
    },
    {
        title: 'Майстер',
        dataIndex: 'master.firstName',
        render: firstName => <div className="cell">{firstName || '---'}</div>,
        width: 200,
    },
    {
        title: 'Серв. центр',
        dataIndex: 'serviceCenter',
        render: data => <div className="cell">{data ? `${data.name} -- ${data.phone}` : '---'}</div>,
        width: 200,
    },
    {
        title: 'Дії',
        dataIndex: 'id',
        key: () => 'action',
        render: id => {
            return <Row wrap={false}>
                <Col lg={11} style={{ textAlign: 'center' }}>
                    <Tooltip title="Редагувати">
                        <Icon type="edit" onClick={() => dispatch(ACTION.edit(id))} style={{ fontSize: 20 }} />
                    </Tooltip>
                </Col>
                <Col lg={11} style={{ textAlign: 'center' }}>
                    <Tooltip title="Відкрити">
                        <Icon type="unlock" onClick={() => dispatch(ACTION.open(id))} style={{ fontSize: 20 }} />
                    </Tooltip>
                </Col>
            </Row>
        },
        width: 200,
        fixed: 'right',
        align: 'center'
    },
];
