import { handleActions } from 'redux-actions';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        [TYPE.FETCH_COLLECTIONS_TRANSACTIONS_LOADING]: (state) => {
            return {
                ...state,
                loading: true
            };
        },
        [TYPE.FETCH_COLLECTIONS_TRANSACTIONS_SUCCESS]: (state, action) => {
            const { content, first, last, size, number } = action.payload;
            return {
                ...state,
                data: content,
                pagination: {
                    first,
                    last,
                    size,
                    page: number
                },
                loading: false
            };
        },
        [TYPE.FETCH_COLLECTIONS_TRANSACTIONS_ERROR]: (state, action) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        },
    },
    {
        loading: false,
        data: [],
        error: null,
        pagination: {
            first: true,
            last: false,
            size: 30,
            page: 0
        },
        filters: {
            startDate: '',
            endDate: '',
            type: '',
            terminal: '',
            searchQuery: '',
        },
    }
);

export default reducer;
