import { put, call, takeLatest, select } from 'redux-saga/effects';
import { pagableRequest, request } from 'api';

import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';
import { showMessage } from '../../../utils/helpers';
import _ from 'lodash';

function* fetchEventsHandler({ payload = {} }) {
    try {
        yield put(ACTION.fetchEventsLoading());

        const res = yield call(pagableRequest(payload), 'events');

        if (!payload.onMount) {
            const eventsList = yield select(selectors.getEvents);
            res.data.content = eventsList.concat(res.data.content);
        }
        yield put(ACTION.fetchEventsSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchEventsError(err));
        console.error(err);
    }
}

function* resolveEventHandler({ payload }) {
    try {
        yield put(ACTION.resolveEventLoading());

        const eventId = yield select(state => _.get(state, 'events.modals.resolveEvent.data.eventId'));

        if (eventId) {
            yield call(request, {
                url: `/events/${eventId}/resolve`,
                method: 'put',
            });

            yield put(ACTION.resolveEventSuccess());
            yield put(ACTION.toggleModal({ modalName: 'resolveEvent', active: false }));
            showMessage({ message: 'Вирішено', type: 'success' });
            yield put(ACTION.fetchEvents({ onMount: true }));
        } else {
            yield put(ACTION.resolveEventFailed());
        }
    } catch (err) {
        showMessage({ message: 'Не вдалося вирішити', type: 'error' });
        yield put(ACTION.toggleModal({ modalName: 'resolveEvent', active: false }));
        console.error('resolveEventHandler', err);
    }
}

function* toggleModalHandler({ payload }) {
    const { modalName, active, data = {} } = payload;
    yield put(ACTION.toggleModalSuccess({ modalName, active, data }));
}

export default [
    takeLatest(TYPE.FETCH_EVENTS, fetchEventsHandler),
    takeLatest(TYPE.RESOLVE_EVENT, resolveEventHandler),
    takeLatest(TYPE.TOGGLE_MODAL, toggleModalHandler),
];
