import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/reports/remainders/actions';
import RemaindersReport from './RemaindersReport';

const getFilteredRemaindersReport = state => {
    return state.reports.remainders.data;
};

const enhancers = [
    connect(
        state => ({
            remainders: state.reports.remainders,
            filteredRemainders: getFilteredRemaindersReport(state),
            clientsFilterValues: state.clients.filterValues,
            totalRemainders: state.reports.remainders.total,
            clientAmount: state.reports.remainders.amount,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(RemaindersReport);
