import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/users_admin/actions';
import Users from './Users';

const getFilteredAdminUsers = state => {
    return state.adminUsers.data;
};

const enhancers = [
    connect(
        state => ({
            adminUsers: state.adminUsers,
            filteredAdminUsers: getFilteredAdminUsers(state)
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Users);
