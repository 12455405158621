import React from 'react';
import { Button } from 'antd';
import dispatch from 'app/dispatch';
import { ACTION } from 'app/ducks/service_centers_admin/actions';


export default [
    {
        title: 'Назва',
        dataIndex: 'name',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Телефон',
        dataIndex: 'phone',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Дії',
        dataIndex: 'id',
        key: 'id',
        render: id => {
            return <Button onClick={() => dispatch(ACTION.edit({ new: false, id }))}>Редагувати</Button>;
        }
    },
];
