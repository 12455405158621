/* eslint-disable arrow-body-style */
import { handleActions } from 'redux-actions';
import { merge } from 'utils/helpers';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        [TYPE.FETCH_CASHIERS_LOADING]: (state) => {
            return merge(state, {
                loading: true
            });
        },
        [TYPE.FETCH_CASHIERS_SUCCESS]: (state, action) => {
            return merge(state, {
                data: action.payload,
                loading: false
            });
        },
        [TYPE.FETCH_CASHIERS_ERROR]: (state, action) => {
            return merge(state, {
                data: [],
                loading: false,
                error: action.payload
            });
        },
        [TYPE.PREPARE_FILTER_SUCCESS]: (state, action) => {
            return {
                ...state,
                filterValues: action.payload
            };
        },

        [TYPE.EDIT]: (state, action) => {
            return merge(state, {
                modals: {
                    editCashier: {
                        active: true,
                        data: action.payload
                    }
                }
            });
        },

        [TYPE.TOGGLE_EDIT_MODAL]: (state, action) => {
            const { payload } = action;

            return merge(state, {
                modals: {
                    editCashier: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            });
        },
    },
    {
        loading: false,
        data: [],
        filterValues: [],
        error: null,
        pagination: {
            size: 25,
            page: 0
        },
        modals: {
            editCashier: {
                active: false,
                data: {},
            },
        },
    }
);

export default reducer;
