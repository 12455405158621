import React from 'react';
import pt from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                auth.isAuthenticated === true
                    ? <Component {...props} />
                    : <Redirect to="/" />
            )}
        />
    );
};

ProtectedRoute.propTypes = {
    component: pt.oneOfType([
        pt.shape({}),
        pt.func
    ]).isRequired,
    auth: pt.shape({
        isAuthenticated: pt.bool.isRequired
    }).isRequired
};

export default connect(
    state => ({
        auth: state.auth
    })
)(ProtectedRoute);
