import React from 'react';
import pt from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _noop from 'lodash/noop';

import ReduxModal from '../ReduxModal';

import './ReduxModalForm.scss';

/** ReduxForm */
const Renderer = props => props.render(props.handleSubmit);

const wrapWithReduxForm = compose(
    connect((state, props) => {
        return {
            form: props.formId,
            initialValues: props.getIntialValues ? props.getIntialValues(props.modalData) : {},
        };
    }),
    reduxForm({ asyncBlurFields: [] })
);
const ReduxForm = wrapWithReduxForm(Renderer);
/** */


const ReduxModalForm = props => <ReduxModal {...props} reduxForm={ReduxForm} />;

ReduxModalForm.propTypes = {
    ...ReduxModal.propTypes,
    formId: pt.string.isRequired,
    getIntialValues: pt.func.isRequired,
    children: pt.elementType.isRequired,
};

ReduxModalForm.defaultProps = ReduxModal.defaultProps;

export default ReduxModalForm;
