import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from 'navigation';

import TransactionsReport from './TransactionsReport';
import BalancesReport from './BalancesReport';
import RemaindersReport from './RemaindersReport';
import Prices from './Prices';
import CashCollectionReport from './CashCollectionReport';

export default () => {
    return (
        <Switch>
            <Route exact path="/reports" render={() => <Redirect to="/reports/transactions" />} />
            <ProtectedRoute path="/reports/transactions" component={TransactionsReport} />
            <ProtectedRoute path="/reports/balances" component={BalancesReport} />
            <ProtectedRoute path="/reports/cash-collection" component={CashCollectionReport} />
            <ProtectedRoute path="/reports/remainders" component={RemaindersReport} />
            <ProtectedRoute path="/reports/prices-history" component={Prices} />
        </Switch>
    );
};
