import React, { useEffect, useCallback, useMemo } from 'react';
import pt from 'prop-types';
import { Button } from 'antd';
import { Field } from 'redux-form';
import get from 'lodash/get';

import { USER_TYPES } from 'app/constants';
import { usersColumns } from 'utils/tablesConfig';
import validators from 'utils/redux-form/validators';
import { Page, ReduxModalForm, ReduxInputs, Table } from 'components/shared';

import './Users.scss';

const availableUserTypes = [USER_TYPES.operator, USER_TYPES.accountant, USER_TYPES.master, USER_TYPES.admin];

const Users = (props) => {
    const { actions, adminUsers, filteredAdminUsers } = props;
    const { fetchUsers, toggleEditModal, submitEdit, edit } = actions;
    const { loading, modals } = adminUsers;

    useEffect(() => {
        fetchUsers();
    }, []);

    const getEditFormIntialValues = useCallback((modalPayload) => {
        if (modalPayload.new) {
            return {
                fullName: '',
                userPass: '',
                userType: USER_TYPES.operator.value,
                userCity: '0',
                userActive: true
            };
        }
        return {
            fullName: `${modalPayload.firstName || ''}${modalPayload.lastName ? ' ' : ''}${modalPayload.lastName || ''}`,
            userPass: '',
            userType: modalPayload.userType,
            userCity: modalPayload.city,
            userActive: modalPayload.enabled
        };
    }, []);

    const openCreateModal = useCallback(() => {
        edit({ new: true });
    }, []);

    const newModalOpen = useMemo(() => !!get(modals, 'editUser.data.new'), [modals]);

    return (
        <Page className="users-admin wide-table">
            <ReduxModalForm
                formId="adminUsersEdit"
                title={newModalOpen ? 'Створення користувача' : 'Редагування користувача'}
                optionsPath="adminUsers.modals.editUser"
                getIntialValues={getEditFormIntialValues}
                toggler={toggleEditModal}
                onSubmit={submitEdit}
                submitButtonText={newModalOpen ? 'Створити' : 'Редагувати'}
            >
                {newModalOpen && (
                    <Field
                        name="userLogin"
                        component={ReduxInputs.Text}
                        id="editUser-login"
                        label="Логін"
                        autocomplete="off"
                        validate={[validators.required]}
                    />
                )}
                <Field
                    name="fullName"
                    component={ReduxInputs.Text}
                    id="editUser-fullName"
                    label="Повне ім'я"
                    autoComplete="off"
                    validate={[validators.required]}
                />
                <Field
                    name="userPass"
                    component={ReduxInputs.Text}
                    id="editUser-pass"
                    type="password"
                    label="Пароль"
                    hint={!newModalOpen && 'Залиште пустим, якщо не бажаєте редагувати'}
                    autoComplete="new-password"
                    validate={newModalOpen && [validators.required]}
                />
                {newModalOpen && (
                    <Field
                        name="userType"
                        component={ReduxInputs.Select}
                        id="editUser-role"
                        label="Роль"
                        validate={newModalOpen && [validators.required]}
                    >
                        {availableUserTypes.map(({ label, value }) => <ReduxInputs.Select.Option value={value} key={value}>{label}</ReduxInputs.Select.Option>)}
                    </Field>
                )}
                <Field
                    name="userActive"
                    component={ReduxInputs.Checkbox}
                    id="editUser-active"
                    label="Активний"
                />
            </ReduxModalForm>

            <div className="container">
                <Button onClick={openCreateModal} style={{ marginBottom: '20px' }}>Додати користувача</Button>
            </div>

            <Table
                rowKey="id"
                data={filteredAdminUsers}
                loading={loading}
                paginationPath={false}
                columns={usersColumns}
            />
        </Page>
    );
};

Users.propTypes = {
    actions: pt.shape({}).isRequired,
    adminUsers: pt.shape({}).isRequired,
    filteredAdminUsers: pt.arrayOf(pt.shape({})),
};

Users.defaultProps = {
    filteredAdminUsers: [],
};

export default Users;
