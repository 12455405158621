import { combineReducers } from 'redux';

import payments from './payments/reducer';
import services from './services/reducer';
import collections from './collections/reducer';
import byAccounts from './byAccounts/reducer';

export default combineReducers({
    payments,
    services,
    collections,
    byAccounts
});
