import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/cash_desks_admin/actions';
import CashDesks from './CashDesks';

const getFilteredAdminCashDesks = state => {
    return state.adminCashDesks.data;
};

const enhancers = [
    connect(
        state => ({
            cashDesks: state.adminCashDesks,
            filteredCashDesks: getFilteredAdminCashDesks(state),
            fopsFilterValues: state.adminFops.filterValues,
            cashiersFilterValues: state.adminCashiers.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(CashDesks);
