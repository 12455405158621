import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/reports/prices/actions';
import Prices from './Prices';


const getFilteredPrices = state => {
    return state.reports.prices.data;
};

const enhancers = [
    connect(
        state => ({
            prices: state.reports.prices,
            filteredPrices: getFilteredPrices(state),
            automatonFilterValues: state.automatons.filterValues,
            mastersFilterValues: state.masters.filterValues,
            citiesFilterValues: state.adminCities.filterValues,
            userFilterValues: state.adminUsers.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Prices);
