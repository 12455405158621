import React from 'react';

import { getTime } from 'utils/helpers';
import { AutomatonNameCell } from 'components/shared';

export default [
    {
        title: 'ID автомата',
        dataIndex: 'automatonId',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Назва автомата',
        dataIndex: 'automaton',
        key: 'automaton.name',
        render: automaton => <AutomatonNameCell automaton={automaton} />,
    },
    {
        title: 'Баланс, грн',
        dataIndex: 'balance',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Дата інкасації',
        dataIndex: 'collectionDate',
        render: text => <div className="cell">{getTime(text).join(' ')}</div>,
    }
];
