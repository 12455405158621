import React, { useEffect, useCallback } from 'react';
import pt from 'prop-types';
import { Spin } from 'antd';

import { event as eventPt } from 'ptEntities';
import { Page, NoData, useInfiniteScroll, Modal } from 'components/shared';
import { EventCard } from 'components/Events';

import './Events.scss';


const Events = (props) => {
    const { actions, events, filteredEvents, modals } = props;
    const { fetchEvents, toggleModal, resolveEvent: resolveEventAction } = actions;
    const { loading, pagination } = events;

    const handleLoadMore = useCallback(() => {
        fetchEvents({ page: pagination.page + 1 });
    }, [pagination.page]);

    const infiniteRef = useInfiniteScroll({
        loading,
        hasNextPage: !pagination.last,
        onLoadMore: handleLoadMore,
    });

    const openModal = (id) => {
        toggleModal({
            modalName: 'resolveEvent',
            active: true,
            data: { eventId: id }
        });
    };

    const closeModal = () => {
        toggleModal({ modalName: 'resolveEvent', active: false });
    };

    useEffect(() => {
        fetchEvents({ onMount: true });
    }, []);

    return (
        <Page className="events">
            <Modal
                formId="resolveEvent"
                title="Вирішити"
                visible={modals.resolveEvent.active}
                onCancel={closeModal}
                onOk={resolveEventAction}
            >
            </Modal>
            <div className="events-list" ref={infiniteRef}>
                {Array.isArray(filteredEvents) && filteredEvents.length > 0
                    ? filteredEvents.map(event => <EventCard event={event} resolveEvent={openModal} key={event.id} />)
                    : !loading && <NoData />
                }
                {loading && <Spin className="spin" />}
            </div>
        </Page>
    );
};

Events.propTypes = {
    actions: pt.shape({}).isRequired,
    events: pt.shape({}).isRequired,
    filteredEvents: pt.arrayOf(eventPt),
};

Events.defaultProps = {
    filteredEvents: [],
};

export default Events;
