import React, { useCallback, Fragment } from 'react';
import pt from 'prop-types';
import { Modal, Col, Row, Button } from 'antd';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import './ReduxModal.scss';

const ReduxModal = props => {
    const { className, children, options, toggler, reduxForm: ReduxForm, submitButtonText, cancelButtonText } = props;
    const { active, data } = options;

    const close = useCallback(() => {
        toggler({ active: false, data: {} });
    });

    return (
        <Modal
            {...props}
            visible={active}
            onCancel={close}
            className={cn('redux-modal', className, { 'no-footer': !!ReduxForm })}
            destroyOnClose
        >
            <section role="dialog" className="wrapper">
                <div className="content">
                    {ReduxForm
                        ? (
                            <ReduxForm
                                modalData={data}
                                render={submit => {
                                    return (
                                        <Fragment>
                                            {props.children}
                                            <hr />
                                            <Row type="flex" justify="end" className="controlls">
                                                <Button className="deny" onClick={close}>{cancelButtonText || 'Відмінити'}</Button>
                                                <Button className="approve" onClick={submit}>{submitButtonText || 'Підтвердити'}</Button>
                                            </Row>
                                        </Fragment>
                                    );
                                }}
                                {...props}
                            />
                        ) : children}
                </div>
            </section>
        </Modal>
    );
};

ReduxModal.propTypes = {
    options: pt.shape({
        active: pt.bool,
        data: pt.shape({})
    }).isRequired,
    toggler: pt.func.isRequired,
    children: pt.elementType,
    reduxForm: pt.elementType,
    title: pt.string,
    className: pt.string,
    submitButtonText: pt.string,
    cancelButtonText: pt.string,
};

ReduxModal.defaultProps = {
    title: '',
    className: '',
    children: undefined,
    reduxForm: undefined,
    submitButtonText: '',
    cancelButtonText: ''
};

const enhanceModal = compose(
    connect((state, props) => ({
        options: get(state, props.optionsPath, {})
    }))
);

const EnhancedModal = enhanceModal(ReduxModal);

export default EnhancedModal;
