import React from 'react';
import makeField from 'utils/redux-form/makeField';
import { NULL } from 'app/constants';
import {
    Icon,
    Input,
    DatePicker as AntdDatePicker,
    Select as AntdSelect,
    Checkbox as AntdCheckbox
} from 'antd';

import './ReduxInputs.scss';
import moment from 'moment';

const DatePickerWrapper = (props) => {
    const defaultProps = {
        showTime: {
            defaultValue: moment('00:00:00', 'HH:mm:ss')
        },
        format: ['DD.MM.YYYY HH:mm:ss', 'DD.MM.YYYY'],
    };
    return <AntdDatePicker {...defaultProps} {...props} />;
};

const RangePickerWrapper = (props) => {
    const defaultProps = {
        showTime: {
            defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]
        },
        format: ['DD.MM.YYYY HH:mm:ss', 'DD.MM.YYYY'],
    };
    return <AntdDatePicker.RangePicker {...defaultProps} {...props} />;
};

// redux-forms replace undefined with an empty string
// this wrapper make select placeholder works
const WrappedSelect = (props) => {
    const defaultProps = { showSearch: true };

    // eslint-disable-next-line react/prop-types
    let { value } = props;
    if (value === '' || value === NULL) {
        value = undefined;
    }

    return (
        <AntdSelect {...defaultProps} {...props} value={value}>
            {value && props.mode !== 'multiple' && (
                <AntdSelect.Option value="">
                    <div className="clear-select"><Icon type="delete" /> Очистити</div>
                </AntdSelect.Option>
            )}
            {props.children}
        </AntdSelect>
    );
};

const ReduxFormSelect = makeField(WrappedSelect);
ReduxFormSelect.Option = AntdSelect.Option;

export const Text = makeField(Input);
export const DatePicker = makeField(DatePickerWrapper);
export const RangePicker = makeField(RangePickerWrapper);
export const Checkbox = makeField(AntdCheckbox);
export const Select = ReduxFormSelect;
