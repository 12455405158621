import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import { TYPE as authActionsTypes } from './auth/actions';

import auth from './auth/reducer';
import automatons from './automatons/reducer';
import masters from './masters/reducer';
import clients from './clients/reducer';
import transactions from './transactions/reducer';
import settings from './settings/reducer';
import events from './events/reducer';
import adminAutomatons from './automatons_admin/reducer';
import adminUsers from './users_admin/reducer';
import adminCities from './cities_admin/reducer';
import adminServiceCenters from './service_centers_admin/reducer';
import adminFops from './fops_admin/reducer';
import adminCashDesks from './cash_desks_admin/reducer';
import adminCashiers from './cashiers_admin/reducer';
import reports from './reports/reducer';
import tax from './tax/reducer';

export default history => (state, action) => {
    if (action.type === authActionsTypes.LOGOUT) {
        //  reset to intial state;
        state = undefined;
    }

    return combineReducers({
        router: connectRouter(history),
        form: formReducer,
        auth,
        automatons,
        masters,
        clients,
        transactions,
        settings,
        events,
        adminAutomatons,
        adminUsers,
        adminCities,
        adminServiceCenters,
        adminFops,
        adminCashDesks,
        adminCashiers,
        reports,
        tax,
    })(state, action);
};
