import React from 'react';
import { status } from 'pages/Events/Events.redux';

const colors = {
    [status.Warning]: 'yellow',
    [status.Error]: 'red',
    [status.Resolved]: 'green',
    default: 'white',
};

export default [
    {
        title: 'ID',
        dataIndex: 'eventId',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Повідомлення',
        dataIndex: 'text',
        render: (text, record) => {
            return <div className="cell">{text}</div>;
        }
    }
];
