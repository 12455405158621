import _ from 'lodash'
import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { Col, Row } from 'antd';

import './AutomatonNameCell.scss';

const AutomatonNameCell = props => {
    const { automaton } = props;
    const [detailedView, setDetailedView] = useState(false);

    const handleCellClick = useCallback(() => {
        setDetailedView(!detailedView);
    }, [detailedView]);

    if (!automaton) {
        return <div>N/A</div>
    }
    if (_.isString(automaton)) {
        // fixes this on turnover reports page to display "Сума"
        return (
            <div className="cell">{automaton}</div>
        )
    }

    const { name, address, simId, city, master } = automaton;
    const masterName = master && master.firstName;
    const cityName = city && city.name;

    return (
        <div className={cn('automaton-name-cell')} onClick={handleCellClick}>
            {detailedView ? (
                    <div className="details">
                        <Col>
                            <Row><h2><strong>{name}</strong></h2></Row>
                            <Row>
                                <Col>
                                    <Row><strong>Адреса:</strong> {address}</Row>
                                    <Row><strong>SIM ID:</strong> {simId}</Row>
                                    {cityName && <Row><strong>Місто:</strong> {cityName}</Row>}
                                    {masterName && <Row><strong>Майстер:</strong> {masterName}</Row>}
                                </Col>
                            </Row>
                        </Col>
                    </div>
                ) : automaton.name || ''
            }
        </div>
    );
};

export default AutomatonNameCell;
