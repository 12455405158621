import React, { useEffect, useCallback, useMemo } from 'react';
import pt from 'prop-types';
import { Button } from 'antd';
import { Field } from 'redux-form';
import get from 'lodash/get';

import { adminCashDesksColumns } from 'utils/tablesConfig';
import validators from 'utils/redux-form/validators';
import { Page, ReduxModalForm, ReduxInputs, Table } from 'components/shared';

import './CashDesks.scss';
import { prepareCashierFilter, prepareFopsFilter } from '../../../utils/by-demand-data-fetch';
import { getNonOccupiedCashiers } from '../../../utils/helpers';

const CashDesks = (props) => {
    const { actions, cashDesks, filteredCashDesks, fopsFilterValues, cashiersFilterValues } = props;
    const { fetchCashDesks, toggleEditModal, submitEdit, edit } = actions;
    const { loading, modals } = cashDesks;

    useEffect(() => {
        fetchCashDesks();
        prepareFopsFilter();
        prepareCashierFilter();
    }, []);

    const getEditFormIntialValues = useCallback((modalPayload) => {
        if (modalPayload.new) {
            return {
                cashRegisterNum: '',
                name: '',
                fopId: '',
                licenseKey: '',
                cashierLogin: '',
                enabled: false,
            };
        }
        return {
            cashRegisterNum: modalPayload.cashRegisterNum,
            name: modalPayload.name,
            fopId: modalPayload.fopId,
            licenseKey: modalPayload.licenseKey,
            enabled: modalPayload.enabled,
            cashierLogin: modalPayload.cashierLogin,
        };
    }, []);

    const openCreateModal = useCallback(() => {
        edit({ new: true });
    }, []);

    const newModalOpen = useMemo(() => !!get(modals, 'editCashDesk.data.new'), [modals]);

    return (
        <Page className="cash-desks-admin wide-table">
            <ReduxModalForm
                formId="adminCashDesksEdit"
                title={newModalOpen ? 'Створення каси' : 'Редагування каси'}
                submitButtonText={newModalOpen ? 'Створити' : 'Редагувати'}
                optionsPath="adminCashDesks.modals.editCashDesk"
                getIntialValues={getEditFormIntialValues}
                toggler={toggleEditModal}
                onSubmit={submitEdit}
            >
                <Field
                    name="cashRegisterNum"
                    component={ReduxInputs.Text}
                    id="cash_desk_rro"
                    label="РРО"
                    disabled={!newModalOpen}
                    validate={[validators.required]}
                />
                <Field
                    name="name"
                    component={ReduxInputs.Text}
                    id="cash_desk_name"
                    label="Назва"
                    validate={[validators.required]}
                />
                <Field
                    name="licenseKey"
                    component={ReduxInputs.Text}
                    id="cash_desk_license_key"
                    label="Ключ ліцензії"
                    validate={[validators.required]}
                />
                <Field
                    name="enabled"
                    component={ReduxInputs.Checkbox}
                    id="enabled"
                    label="Активна"
                />


                <Field
                    name="cashierLogin"
                    id="cash_desk_cashier"
                    component={ReduxInputs.Select}
                    validate={[validators.required]}
                    label="Касир"
                >
                    {getNonOccupiedCashiers(filteredCashDesks || [],cashiersFilterValues || []).map(cashier => (
                        <ReduxInputs.Select.Option key={cashier.login} value={cashier.login}>
                            {cashier.login} -- {cashier.name}
                        </ReduxInputs.Select.Option>
                    ))}
                </Field>


                <Field
                    name="fopId"
                    id="cash_desk_fop"
                    component={ReduxInputs.Select}
                    validate={[validators.required]}
                    label="ФОП"
                >
                    {fopsFilterValues.map(fop => (
                        <ReduxInputs.Select.Option key={fop.id} value={fop.id}>
                            {fop.name}
                        </ReduxInputs.Select.Option>
                    ))}
                </Field>
            </ReduxModalForm>

            <div className="container">
                <Button onClick={openCreateModal} style={{ marginBottom: '20px' }}>Додати касу</Button>
            </div>

            <Table
                rowKey="id"
                rowClassName={record => record.className}
                data={filteredCashDesks}
                loading={loading}
                paginationPath={false}
                columns={adminCashDesksColumns}
            />
        </Page>
    );
};

CashDesks.propTypes = {
    actions: pt.shape({}).isRequired,
};

CashDesks.defaultProps = {
};

export default CashDesks;
