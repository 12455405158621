import React from 'react'
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { ReduxInputs } from 'components/shared';

import './Filters.scss';

const Filters = () => {
    return (
        <div className="filters">
            <Field
                name="searchQuery"
                component={ReduxInputs.Text}
                placeholder="Пошук автомату"
                id="automatonsSearchQuery"
                inputSpan={24}
            />
        </div>
    );
};

Filters.propTypes = {

}

export default Filters;
