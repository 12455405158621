import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/reports/cash_collection/actions';
import CashCollectionReport from './CashCollectionReport';

const getFilteredReports = state => {
    return state.reports.cashCollection.data;
};

const enhancers = [
    connect(
        state => ({
            cashCollection: state.reports.cashCollection,
            filteredCashCollection: getFilteredReports(state),
            automatonFilterValues: state.automatons.filterValues,
            mastersFilterValues: state.masters.filterValues,
            citiesFilterValues: state.adminCities.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(CashCollectionReport);
