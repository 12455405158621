import { put, call, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { showMessage } from 'utils/helpers';

import { request } from 'api';
import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* fetchCitiesHandler() {
    try {
        yield put(ACTION.fetchCitiesLoading());

        const res = yield call(request, 'account-admin/cities');

        yield put(ACTION.fetchCitiesSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchCitiesError(err));
        console.error('fetchCitiesHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'account-admin/cities');
        const filtersData = _.get(res, 'data', []);
        yield put(ACTION.prepareFilterSuccess(filtersData));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

function* editCityHandler({ payload }) {
    try {
        if (payload.new) {
            yield put(ACTION.toggleEditModal({ active: true, data: payload }));
        } else {
            const city = yield select(selectors.getCityById(payload.id));

            if (!city) {
                throw new Error('Edit: Choosen city not found.');
            } else {
                yield put(ACTION.toggleEditModal({ active: true, data: city }));
            }
        }
    } catch (err) {
        console.error('editCityHandler', err);
    }
}

function* submitEditHandler({ payload = {} }) {
    try {
        const body = {
            name: payload.cityName
        };
        const isNew = yield select(selectors.isNewModal('editCity'));

        if (isNew) {
            yield call(request, {
                method: 'POST',
                url: 'account-admin/cities',
                data: body
            });
            showMessage({ message: 'Створено місто:', description: payload.cityName, type: 'success' });
        } else {
            const editedCity = yield select(selectors.getModalData('editCity'));
            if (!editedCity) {
                throw new Error('Failed to get modal data!');
            }
            yield call(request, {
                method: 'PUT',
                url: `account-admin/cities/${editedCity.id}`,
                data: body
            });
            showMessage({ message: 'Відредаговано успішною', type: 'success' });
        }

        yield put(ACTION.toggleEditModal({ active: false, data: {} }));
        yield put(ACTION.fetchCities());
        yield put(ACTION.prepareFilter());
    } catch (err) {
        console.error('submitEditHandler', err);
    }
}

export default [
    takeLatest(TYPE.FETCH_CITIES, fetchCitiesHandler),
    takeLatest(TYPE.EDIT, editCityHandler),
    takeLatest(TYPE.SUBMIT_EDIT, submitEditHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
];
