import { put, call, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import { request } from 'api';
import { TYPE, ACTION } from './actions';

function* fetchClientsHandler() {
    try {
        yield put(ACTION.fetchClientsLoading());

        const res = yield call(request, 'reports/client-balance');

        // Workaround while we don't have endpoint to fetch clients
        const clientIds = _(res.data).map(({ clientId }) => clientId).uniq().value();
        yield put(ACTION.fetchClientsSuccess(clientIds));
    } catch (err) {
        yield put(ACTION.fetchClientsError(err));
        console.error('fetchClientsHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'reports/client-balance');

        // Workaround while we don't have endpoint to fetch clients
        const clientIds = _(res.data).map(({ clientId }) => clientId).uniq().value();
        yield put(ACTION.prepareFilterSuccess(clientIds));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

export default [
    takeLatest(TYPE.FETCH_CLIENTS, fetchClientsHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
];
