import { constants, actions } from 'ducks-helpers';

//  SUFFIXES
// [
//   'LOADING',
//   'PENDING',
//   'SUCCESS',
//   'ERROR',
//   'FAILED',
//   'CANCELED'
// ]

export const TYPE = constants('admin/automatons', [
    '~FETCH_AUTOMATONS',

    /** EDIT ACTIONS */
    'EDIT',
    'TOGGLE_EDIT_MODAL',
    'SUBMIT_EDIT',

    'OPEN',
    'TOGGLE_OPEN_MODAL',
    'SUBMIT_OPEN',

    'TOGGLE_SET_PRICE_MODAL',
    'SUBMIT_SET_PRICE_MODAL',

    'TOGGLE_SET_MASTER_MODAL',
    'SUBMIT_SET_MASTER_MODAL',

    'TOGGLE_SET_TEMPERATURE_MODAL',
    'SUBMIT_SET_TEMPERATURE_MODAL',
    /** */

    /** SELECT ACTIONS */
    'TOGGLE_ALL',
    'TOGGLE_BY_ID',
    'SET_SELECTED_ALL',
    'UPDATE_AUTOMATONS'
    /** */
]);

export const ACTION = actions(TYPE);
