import { put, call, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import { request } from 'api';
import { TYPE, ACTION } from './actions';

function* fetchMastersHandler() {
    try {
        yield put(ACTION.fetchMastersLoading());

        const res = yield call(request, 'masters');

        yield put(ACTION.fetchMastersSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchMastersError(err));
        console.error('fetchMastersHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'masters');
        const filtersData = _.get(res, 'data', []);
        yield put(ACTION.prepareFilterSuccess(filtersData));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

export default [
    takeLatest(TYPE.FETCH_MASTERS, fetchMastersHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
];
