import get from 'lodash/get';

export const getFilters = state => state.form.adminServiceCentersFilters;

export const getModalData = modalName => state => get(state, `adminServiceCenters.modals.${modalName}.data`, {});

export const getServiceCenterById = id => state => {
    const cities = get(state, 'adminServiceCenters.data', []);
    return cities.find((a) => a.id === id);
};

export const isNewModal = modalName => state => {
    return get(state, `adminServiceCenters.modals.${modalName}.data.new`, false);
};
