import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/events/actions';
import Events from './Events';

export const status = {
    ERROR: { value: 'ERROR', className: 'error' },
    WARNING: { value: 'WARNING', className: 'warning' },
    RESOLVED: { value: 'RESOLVED', className: 'resolved' },
};

const getFilteredEvents = state => {
    return state.events.data;
};

const enhancers = [
    connect(
        state => ({
            events: state.events,
            filteredEvents: getFilteredEvents(state),
            modals: state.events.modals,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Events);
