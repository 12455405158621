import React, { useCallback, useEffect } from 'react';
import pt from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Input, Button, Icon } from 'antd';

import { ACTION } from 'app/ducks/auth/actions';
import makeField from 'utils/redux-form/makeField';
import { required } from 'utils/redux-form/validationMessages';

import './Login.scss';

const TextInput = makeField(Input);

const loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Login = ({ login, resetLogin, handleSubmit, pending }) => {
    useEffect(() => {
        resetLogin();
    }, []);

    const submit = useCallback(e => {
        e.preventDefault();
        if (!pending) {
            handleSubmit(login)();
        }
    }, [pending, handleSubmit, login]);

    return (
        <Row className="login" type="flex" justify="center">
            <Col xs={18} md={14} lg={8}>
                <form className="form" onSubmit={submit}>
                    <div className="title">Вхід</div>
                    <div className="content">
                        <Field
                            label="Логін"
                            name="username"
                            component={TextInput}
                            placeholder="Введіть ім'я користувача"
                            id="usernameField"
                            autocomplete="username"
                        />
                        <Field
                            label="Пароль"
                            name="password"
                            component={TextInput}
                            type="password"
                            placeholder="Введіть пароль"
                            id="passwordField"
                            autocomplete="current-password"
                        />

                        <Row type="flex" justify="center">
                            <Button htmlType="submit">{pending ? loadingIcon : 'Увійти'}</Button>
                        </Row>
                    </div>
                </form>
            </Col>
        </Row>
    );
};

Login.propTypes = {
    pending: pt.bool.isRequired,
    login: pt.func.isRequired,
    handleSubmit: pt.func.isRequired
};

const validate = (form) => {
    const errors = {};

    if (!form.username) {
        errors.username = required;
    }

    if (!form.password) {
        errors.password = required;
    }

    return errors;
};

export default reduxForm({ form: 'login', validate })(connect(
    store => ({
        pending: store.auth.pending,
    }),
    dispatch => ({
        login: payload => dispatch(ACTION.login(payload)),
        resetLogin: () => dispatch(ACTION.resetLogin()),
    })
)(Login));
