/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useResizeDetector } from 'react-resize-detector';
import pt from 'prop-types';
import { Spin, Table as AntTable } from 'antd';

import { PrevNextControl } from 'components/shared';

import './Table.scss';

const PREV_NEXT_CONTROLS_HEIGHT = 56 * 2;

const Table = ({ className, data, paginationPath, onFetch, loading, horizontal, ...rest }) => {
    
    /* Header height */
    const [headerHeight, setHeaderHeight] = useState(null);  
    const handleHeaderHeightChange = useCallback((header) => {
        console.log('Header', header);
        if (header) {
            console.log('Header inner', header);
            const height = header.clientHeight;
            setHeaderHeight(height);
        }
    }, []);

    /* Entire height */
    const { height: entireHeight, ref: tableRef } = useResizeDetector();

    /* Calculate body height */
    const [bodyHeight, setBodyHeight] = useState(null);
    useEffect(() => {
        console.log('Header height: ', headerHeight);
        console.log('Entire height: ', entireHeight);

        if (entireHeight > 0 && headerHeight > 0) {
            setBodyHeight(entireHeight - headerHeight - (paginationPath ? PREV_NEXT_CONTROLS_HEIGHT : 0));
        }
    }, [entireHeight, headerHeight]);


    return (
        <div className={cn('table-component', className)} ref={tableRef}>
            {loading ? (
                <Spin className="spin" />
            ) : Array.isArray(data) && (
                <div className="table-wrapper">
                    {paginationPath && (
                        <PrevNextControl
                            dataPath={paginationPath}
                            onChange={onFetch}
                        />
                    )}
                    <AntTable
                        dataSource={data}
                        pagination={false}
                        scroll={{ y: bodyHeight, x: horizontal }}
                        onHeaderRow={() => {
                            return {
                                ref: handleHeaderHeightChange,
                            };
                        }}                        
                        {...rest}
                    />
                    {paginationPath && (
                        <PrevNextControl
                            dataPath={paginationPath}
                            onChange={onFetch}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

Table.propTypes = {
    loading: pt.bool.isRequired,
    columns: pt.arrayOf(pt.shape({})).isRequired,
    data: pt.arrayOf(pt.shape({})),
    paginationPath: pt.string,
    onFetch: pt.func,
};

Table.defaultProps = {
    data: [],
    paginationPath: '',
    onFetch: null
};

export default Table;
