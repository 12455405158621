import React, { useEffect, useCallback } from 'react';
import _get from 'lodash/get';
import pt from 'prop-types';
import { Row, Col, Button, Icon } from 'antd';
import { Field } from 'redux-form';

import { adminAutomatonsColumns } from 'utils/tablesConfig';
import validators from 'utils/redux-form/validators';
import { transaction } from 'ptEntities';
import { Page, ReduxModalForm, ReduxInputs, Filters, Table } from 'components/shared';

import {
    prepareAutomatonFilter,
    prepareCityFilter,
    prepareMasterFilter,
    prepareServiceCenterFilter,
    prepareCashDeskFilter,
} from 'utils/by-demand-data-fetch';

import './AutomatonsAdmin.scss';

const TransactionsReport = (props) => {
    const {
        actions,
        adminAutomatons,
        filteredAdminAutomatons,
        automatonFilterValues,
        citiesFilterValues,
        mastersFilterValues,
        serviceCentersFilterValues,
        cashDesksFilterValues,
    } = props;
    const {
        fetchAutomatons, toggleEditModal,
        toggleSetPriceModal, toggleSetMasterModal, toggleSetTemperatureModal,
        submitEdit, submitSetPriceModal, submitSetTemperatureModal,
        submitSetMasterModal, toggleOpenModal,
        submitOpen
    } = actions;
    const { loading } = adminAutomatons;

    useEffect(() => {
        fetchAutomatons();
        prepareAutomatonFilter();
        prepareCityFilter();
        prepareServiceCenterFilter();
        prepareMasterFilter();
        prepareCashDeskFilter();
    }, []);

    const getEditFormIntialValues = useCallback((modalPayload) => {
        return {
            automatonName: _get(modalPayload, 'name'),
            price: _get(modalPayload, 'settings.pricePerLitre'),
            address: _get(modalPayload, 'address'),
            tempInside: _get(modalPayload, 'settings.indoorTemp'),
            tempCash: _get(modalPayload, 'settings.banknoteTemp'),
            impulseCount: _get(modalPayload, 'settings.impulsePerLitre'),
            city: _get(modalPayload, 'city.id'),
            master: _get(modalPayload, 'master.id'),
            serviceCenter: _get(modalPayload, 'serviceCenter.id'),
            cashRegisterNum: _get(modalPayload, 'cashRegisterNum'),
        };
    }, []);

    const getSetPriceFormInitialValues = useCallback(() => {
        return {
            automatons: filteredAdminAutomatons.filter(a => a.selected).map(a => a.id)
        }
    }, [filteredAdminAutomatons]);

    const getSetMasterFormInitialValues = useCallback(() => {
        return {
            automatons: filteredAdminAutomatons.filter(a => a.selected).map(a => a.id)
        }
    }, [filteredAdminAutomatons]);

    const getSetTemperatureFormInitialValues = useCallback(() => {
        return {
            automatons: filteredAdminAutomatons.filter(a => a.selected).map(a => a.id)
        }
    }, [filteredAdminAutomatons]);

    return (
        <Page className="automatons-admin wide-table">
            <div className="container">
                <Filters formId="adminAutomatonsFilters" initialValues={{ cities: [], automatons: [], masters: [] }}>
                    <Row type="flex" justify="space-between">
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="masters"
                                id="filter-adutomaton-masters"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Майстри"
                                mode="multiple"
                            >
                                {mastersFilterValues.map(master => (
                                    <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                        {master.firstName} {master.lastName}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="filter-adutomaton-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id}
                                                                                               value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="cities"
                                id="filter-adutomaton-city"
                                component={ReduxInputs.Select}
                                placeholder="Міста"
                                inputSpan={24}
                                mode="multiple"
                            >
                                {citiesFilterValues.map(city => (
                                    <ReduxInputs.Select.Option key={city.id} value={city.id}>
                                        {city.name}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-between">
                        <Col xs={24} sm={{ span: 11 }}>
                            <Field
                                name="address"
                                component={ReduxInputs.Text}
                                id="filter-automaton-address"
                                placeholder="Адреса"
                                inputSpan={24}
                            />
                        </Col>
                        <Col xs={24} sm={{ span: 11 }}>
                            <Field
                                name="cashRegisterNum"
                                component={ReduxInputs.Text}
                                id="filter-automaton-cashRegisterNum"
                                placeholder="РРО"
                                inputSpan={24}
                            />
                        </Col>
                        <Col xs={24} sm={{ span: 11 }}>
                            <Field
                                name="simId"
                                component={ReduxInputs.Text}
                                id="filter-automaton-address"
                                placeholder="SIM ID"
                                inputSpan={24}
                            />
                        </Col>
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary"
                                onClick={fetchAutomatons}><span>Застосувати фільтри</span></Button>
                    </div>
                </Filters>
                <Button onClick={() => toggleSetPriceModal({ active: true })}
                        style={{ marginBottom: '20px', marginRight: '20px' }}>Встановити ціну</Button>
                <Button onClick={() => toggleSetMasterModal({ active: true })}
                        style={{ marginBottom: '20px', marginRight: '20px' }}>Встановити майстра</Button>
                <Button onClick={() => toggleSetTemperatureModal({ active: true })}
                        style={{ marginBottom: '20px', marginRight: '20px' }}>Встановити температуру</Button>
            </div>

            <ReduxModalForm
                formId="adminMastersAssign"
                title="Встановлення майстра"
                submitButtonText="Встановити"
                optionsPath="adminAutomatons.modals.setMaster"
                getIntialValues={getSetMasterFormInitialValues}
                toggler={toggleSetMasterModal}
                onSubmit={submitSetMasterModal}
            >
                <Row type="flex" justify="space-between">
                    <Col xs={24} sm={{ span: 11 }}>
                        <Field
                            name="automatons"
                            id="filter-adutomaton-automaton"
                            component={ReduxInputs.Select}
                            inputSpan={24}
                            placeholder="Автомати"
                            mode="multiple"
                            validate={[validators.arrayRequired]}
                        >
                            {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id}
                                                                                           value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                        </Field>
                    </Col>
                    <Col xs={24} sm={{ span: 11 }}>
                        <Field
                            name="master"
                            id="filter-adutomaton-master"
                            component={ReduxInputs.Select}
                            placeholder="Майстер"
                            inputSpan={24}
                            validate={[validators.required]}
                        >
                            {mastersFilterValues.map(master => (
                                <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                    {master.firstName}
                                </ReduxInputs.Select.Option>
                            ))}
                        </Field>
                    </Col>
                </Row>
            </ReduxModalForm>

            <ReduxModalForm
                formId="adminPricesEdit"
                title="Встановлення ціни"
                submitButtonText="Встановити"
                optionsPath="adminAutomatons.modals.setPrice"
                getIntialValues={getSetPriceFormInitialValues}
                toggler={toggleSetPriceModal}
                onSubmit={submitSetPriceModal}
            >
                <Row type="flex" justify="space-between">
                    <Col xs={24} sm={{ span: 11 }}>
                        <Field
                            name="automatons"
                            id="filter-adutomaton-automaton"
                            component={ReduxInputs.Select}
                            inputSpan={24}
                            placeholder="Автомати"
                            mode="multiple"
                        >
                            {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id}
                                                                                           value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                        </Field>
                    </Col>
                    <Col xs={24} sm={{ span: 11 }}>
                        <Field
                            name="cities"
                            id="filter-adutomaton-city"
                            component={ReduxInputs.Select}
                            placeholder="Міста"
                            inputSpan={24}
                            mode="multiple"
                        >
                            {citiesFilterValues.map(city => (
                                <ReduxInputs.Select.Option key={city.id} value={city.id}>
                                    {city.name}
                                </ReduxInputs.Select.Option>
                            ))}
                        </Field>
                    </Col>
                </Row>
                <Field
                    name="pricePerLitr"
                    component={ReduxInputs.Text}
                    id="price_price"
                    label="Ціна за літр"
                    validate={[validators.required, validators.number]}
                />
            </ReduxModalForm>

            <ReduxModalForm
                formId="adminTemperatureEdit"
                title="Встановлення температури"
                submitButtonText="Встановити"
                optionsPath="adminAutomatons.modals.setTemperature"
                getIntialValues={getSetTemperatureFormInitialValues}
                toggler={toggleSetTemperatureModal}
                onSubmit={submitSetTemperatureModal}
            >
                <Row type="flex" justify="space-between">
                    <Col xs={24} sm={{ span: 11 }}>
                        <Field
                            name="automatons"
                            id="filter-adutomaton-automaton"
                            component={ReduxInputs.Select}
                            inputSpan={24}
                            placeholder="Автомати"
                            mode="multiple"
                        >
                            {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id}
                                                                                           value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                        </Field>
                    </Col>
                    <Col xs={24} sm={{ span: 11 }}>
                        <Field
                            name="cities"
                            id="filter-adutomaton-city"
                            component={ReduxInputs.Select}
                            placeholder="Міста"
                            inputSpan={24}
                            mode="multiple"
                        >
                            {citiesFilterValues.map(city => (
                                <ReduxInputs.Select.Option key={city.id} value={city.id}>
                                    {city.id} -- {city.name}
                                </ReduxInputs.Select.Option>
                            ))}
                        </Field>
                    </Col>
                </Row>
                <Field
                    name="tempInside"
                    component={ReduxInputs.Text}
                    id="temp_inside"
                    label={<>t <Icon type="hdd"/>, &deg;C</>}
                    validate={[validators.required, validators.number]}
                />
                <Field
                    name="tempCash"
                    component={ReduxInputs.Text}
                    id="temp_cash"
                    label={<>t <Icon type="dollar"/>, &deg;C</>}
                    validate={[validators.required, validators.number]}
                />
            </ReduxModalForm>

            <ReduxModalForm
                formId="adminAutomatonsOpen"
                title="Ви впевнені?"
                optionsPath="adminAutomatons.modals.openAutomaton"
                submitButtonText="Так"
                cancelButtonText="Ні"
                className="agreement-modal"
                // getIntialValues={getEditFormIntialValues}
                toggler={toggleOpenModal}
                onSubmit={submitOpen}
            >

            </ReduxModalForm>

            <ReduxModalForm
                formId="adminAutomatonsEdit"
                title="Редагування автомата"
                optionsPath="adminAutomatons.modals.editAutomaton"
                getIntialValues={getEditFormIntialValues}
                toggler={toggleEditModal}
                onSubmit={submitEdit}
            >
                <Field
                    name="automatonName"
                    component={ReduxInputs.Text}
                    id="automaton_name"
                    label="Назва"
                    validate={[validators.required]}
                />
                <Field
                    name="address"
                    component={ReduxInputs.Text}
                    id="automaton_address"
                    label="Адреса"
                    validate={[validators.required]}
                />
                <Field
                    name="price"
                    component={ReduxInputs.Text}
                    id="automaton_price"
                    label="Ціна"
                    validate={[validators.required, validators.number, validators.number.positive]}
                />
                <Field
                    name="tempInside"
                    component={ReduxInputs.Text}
                    id="automaton_tempi"
                    label={<>t <Icon type="hdd"/>, &deg;C</>}
                    validate={[validators.required, validators.number]}
                />
                <Field
                    name="tempCash"
                    component={ReduxInputs.Text}
                    id="automaton_tempc"
                    label={<>t <Icon type="dollar"/>, &deg;C</>}
                    validate={[validators.required, validators.number]}
                />
                <Field
                    name="impulseCount"
                    component={ReduxInputs.Text}
                    id="automaton_impulse"
                    label="імпусьсів/л"
                    validate={[validators.required, validators.number, validators.number.positive]}
                />
                <Field
                    name="cashRegisterNum"
                    component={ReduxInputs.Select}
                    id="automaton_cashRegisterNum"
                    label="РРО"
                    validate={[]}
                >
                    {
                        cashDesksFilterValues
                            .map(desk => (
                                <ReduxInputs.Select.Option key={desk.cashRegisterNum} value={desk.cashRegisterNum}>
                                    {desk.cashRegisterNum} -- {desk.name}
                                </ReduxInputs.Select.Option>
                            ))
                    }
                </Field>
                <Field
                    name="city"
                    id="automaton_city"
                    component={ReduxInputs.Select}
                    label="Місто"
                >
                    {citiesFilterValues.map(city => (
                        <ReduxInputs.Select.Option key={city.id} value={city.id}>
                            {city.name}
                        </ReduxInputs.Select.Option>
                    ))}
                </Field>
                <Field
                    name="serviceCenter"
                    id="automaton_service_center"
                    component={ReduxInputs.Select}
                    label="Сервісний центр"
                >
                    {serviceCentersFilterValues.map(serviceCenter => (
                        <ReduxInputs.Select.Option key={serviceCenter.id} value={serviceCenter.id}>
                            {`${serviceCenter.name} -- ${serviceCenter.phone}`}
                        </ReduxInputs.Select.Option>
                    ))}
                </Field>
                <Field
                    name="master"
                    id="automaton_master"
                    component={ReduxInputs.Select}
                    label="Майстер"
                >
                    {mastersFilterValues.map(master => (
                        <ReduxInputs.Select.Option key={master.id} value={master.id}>
                            {master.firstName} {master.lastName}
                        </ReduxInputs.Select.Option>
                    ))}
                </Field>
            </ReduxModalForm>

            <Table
                rowKey="id"
                rowClassName={record => record.className}
                data={filteredAdminAutomatons}
                loading={loading}
                paginationPath={false}
                columns={adminAutomatonsColumns}
                horizontal={1920}
            />
        </Page>
    );
};

TransactionsReport.propTypes = {
    actions: pt.shape({}).isRequired,
    adminAutomatons: pt.shape({}).isRequired,
    filteredAdminAutomatons: pt.arrayOf(transaction),
};

TransactionsReport.defaultProps = {
    filteredAdminAutomatons: [],
};

export default TransactionsReport;
