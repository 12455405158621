import React, { useCallback } from 'react';
import cn from 'classnames';
import { Button, Col, Row } from 'antd';

import { event as eventType } from 'ptEntities';
import { getTime, resolveEventColor } from 'utils/helpers';
import AuthzService from 'services/authorization';

import './EventCard.scss';
import { ROLES } from '../../../app/constants';

const EventCard = ({ event, resolveEvent }) => {
    const authzService = React.useContext(AuthzService.context);
    const { id, automaton, description, message, resolved, date } = event;
    const [formattedDate, formattedTime] = getTime(date);

    let eventColor = {};
    if (!resolved) {
        eventColor = resolveEventColor(event);
    }

    const handleResolveEvent = useCallback(() => {
        resolveEvent(id);
    }, [resolveEvent, event]);

    const hasEventsResolveAccess = authzService.hasAccess({ role: ROLES.ROLE_EVENTS_RESOLVE });

    return (
        <article
            className={cn('event-card')}
            style={{ backgroundColor: eventColor.backgroundColor, color: eventColor.fontColor }}
        >
            <Row>
                <Col span={24}>
                    <Row style={{ overflowWrap: 'break-word' }}>
                        Повідомлення: {message || description.text}
                    </Row>
                    <Row>
                        <Col span={12}>
                            Код: {description.code}
                        </Col>
                        <Col span={12}>
                            Дата: {formattedDate} {formattedTime}
                        </Col>
                    </Row>
                    {automaton && (
                        <Row>
                            <hr />
                            <Row>
                                <Col span={12}>
                                    ID: {automaton.id}
                                </Col>
                                <Col span={12}>
                                    Адреса: {automaton.address}
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    Автомат: {automaton.name}
                                </Col>
                            </Row>
                        </Row>
                    )}
                </Col>
            </Row>
            {!resolved && hasEventsResolveAccess && (
                <Row>
                    <Button onClick={handleResolveEvent} style={{ marginTop: '20px' }}>Вирішити</Button>
                </Row>
            )}
        </article>
    );
};

EventCard.propTypes = {
    event: eventType.isRequired,
};

export default EventCard;
