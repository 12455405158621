import React from 'react';
import pt from 'prop-types';
import { Modal } from 'antd';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import get from 'lodash/get';

import './Modal.scss';

const ModalWrapper = props => {
    const { className, children } = props;
    return (
        <Modal {...props} className={cn('common-modal', className)}>
            <section role="dialog" className="wrapper">
                <div className="content">
                    {children}
                </div>
            </section>
        </Modal>
    );
};

ModalWrapper.propTypes = {
    title: pt.string,
    className: pt.string
};

ModalWrapper.defaultProps = {
    title: '',
    className: '',
};


const enhanceModal = compose(
    connect((state, props) => ({
        form: props.formId,
    })),
    reduxForm({ asyncBlurFields: [] })
);

// const EnhancedModal = enhanceModal(
//     connect(
//         (state, props) => ({
//             initialValues: props.initialValues
//         })
//     )(ModalWrapper)
// );
const EnhancedModal = enhanceModal(ModalWrapper);

export default EnhancedModal;
