import { handleActions } from 'redux-actions';
import { merge } from 'utils/helpers';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        // FETCH_BALANCE_REPORT
        [TYPE.FETCH_BALANCES_REPORT_LOADING]: (state) => {
            return merge(state, {
                loading: true
            });
        },
        [TYPE.FETCH_BALANCES_REPORT_SUCCESS]: (state, action) => {
            return merge(state, {
                data: action.payload,
                loading: false
            });
        },
        [TYPE.FETCH_BALANCES_REPORT_ERROR]: (state, action) => {
            return merge(state, {
                data: [],
                loading: false,
                error: action.payload
            });
        },
    },
    {
        loading: false,
        data: [],
        error: null,
        pagination: {
            size: 25,
            page: 0
        },
        filters: {
            startDate: '',
            endDate: '',
            type: '',
            terminal: '',
            searchQuery: '',
        },
    }
);

export default reducer;
