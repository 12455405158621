import _ from 'lodash';
import { store } from 'App';
import dispatch from 'app/dispatch';
import { ACTION as AUTOMATONS_ACTIONS } from 'app/ducks/automatons/actions';
import { ACTION as MASTERS_ACTIONS } from 'app/ducks/masters/actions';
import { ACTION as CLIENTS_ACTIONS } from 'app/ducks/clients/actions';
import { ACTION as USERS_ACTIONS } from 'app/ducks/users_admin/actions';
import { ACTION as CITIES_ACTIONS } from 'app/ducks/cities_admin/actions';
import { ACTION as SERVICE_CENTERS } from 'app/ducks/service_centers_admin/actions';
import { ACTION as FOPS } from 'app/ducks/fops_admin/actions';
import { ACTION as CASH_DESKS } from 'app/ducks/cash_desks_admin/actions';
import { ACTION as CASHIERS } from 'app/ducks/cashiers_admin/actions';

const getField = (path, defaultValue) => {
    const state = store.getState();
    return _.get(state, path, defaultValue);
};

const createFetchFunc = ({ path, defaultValue, action, fetchPredicate }) => () => {
    const data = getField(path, defaultValue);

    if ((fetchPredicate && fetchPredicate(data)) || _.size(data) === 0) {
        dispatch(action());
    }
};

export const prepareAutomatonFilter = createFetchFunc({
    path: 'automatons.filterValues',
    defaultValue: [],
    action: AUTOMATONS_ACTIONS.prepareFilter
});

export const prepareMasterFilter = createFetchFunc({
    path: 'masters.filterValues',
    defaultValue: [],
    action: MASTERS_ACTIONS.prepareFilter
});

export const prepareClientFilter = createFetchFunc({
    path: 'clients.filterValues',
    defaultValue: [],
    action: CLIENTS_ACTIONS.prepareFilter
});

export const prepareUserFilter = createFetchFunc({
    path: 'adminUsers.filterValues',
    defaultValue: [],
    action: USERS_ACTIONS.prepareFilter
});

export const prepareCityFilter = createFetchFunc({
    path: 'adminCities.filterValues',
    defaultValue: [],
    action: CITIES_ACTIONS.prepareFilter
});

export const prepareServiceCenterFilter = createFetchFunc({
    path: 'adminServiceCenters.filterValues',
    defaultValue: [],
    action: SERVICE_CENTERS.prepareFilter
});

export const prepareFopsFilter = createFetchFunc({
    path: 'adminFops.filterValues',
    defaultValue: [],
    action: FOPS.prepareFilter
});

export const prepareCashDeskFilter = createFetchFunc({
    path: 'adminCashDesks.filterValues',
    defaultValue: [],
    action: CASH_DESKS.prepareFilter
});

export const prepareCashierFilter = createFetchFunc({
    path: 'adminCashiers.filterValues',
    defaultValue: [],
    action: CASHIERS.prepareFilter
});
