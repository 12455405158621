import React, { useCallback, useState } from 'react';
import pt from 'prop-types';
import cn from 'classnames';
import { Button, Col, Row, Icon } from 'antd';

import AuthzService from 'services/authorization';
import { ROLES } from 'app/constants';
import { automaton as automatonType } from 'ptEntities';
import { getStatusColor, resolveEventColor, translateStatus } from 'utils/helpers';

import './AutomatonCard.scss';

const AutomatonCard = ({ automaton, releaseWater }) => {
    const authzService = React.useContext(AuthzService.context);

    const { id, name, address, simId, state, events, online, city, master, serviceCenter, cashRegisterNum, cashDeskStatus } = automaton;
    const { water, cash, currIndoorTemp, currBanknoteTemp, energyCounter, signalLevel } = state;
    const masterName = master && master.firstName;
    const cityName = city && city.name;

    const handleReleaseWater = useCallback(() => {
        releaseWater(id);
    }, [releaseWater, automaton]);

    const [showEvents, setShowEvents] = useState(true);
    const toggleShowEvents = useCallback(() => {
        setShowEvents(!showEvents);
    }, [showEvents]);

    const hasMoneyAccess = authzService.hasAccess({ role: ROLES.ROLE_AUTOMATON_READ_MONEY });
    const hasReleaseWaterAccess = authzService.hasAccess({ role: ROLES.ROLE_AUTOMATON_RELEASE_WATER });

    return (
        <article className={cn('automaton-card')}>
            <div className="online-status">
                <span>{online ? 'Онлайн' : 'Офлайн'}</span>
                <i className={cn({ online })} />
            </div>

            <div className="content">
                <Row className="main-info">
                    <Col xs={24} sm={18}>
                        <Row><h2><strong>{name}</strong></h2></Row>
                        <Row>
                            <Col xs={8}>
                                {address && <Row>Адреса: </Row>}
                                {id !== undefined && <Row>Номер автомата: </Row>}
                                {simId !== undefined && <Row>ID сімкарти: </Row>}
                                {cityName && <Row>Місто: </Row>}
                                {masterName && <Row>Майстер: </Row>}
                                {serviceCenter && <Row>Серв. центр: </Row>}
                                {cashRegisterNum && <Row>РРО: </Row>}
                                {cashDeskStatus && <Row style={{fontSize: '18px'}}>Статус зміни: </Row>}
                            </Col>
                            <Col xs={16}>
                                {address !== undefined && <Row>{address}</Row>}
                                {id !== undefined && <Row>{id}</Row>}
                                {simId !== undefined && <Row>{simId}</Row>}
                                {cityName && <Row>{cityName}</Row>}
                                {masterName && <Row>{masterName}</Row>}
                                {serviceCenter && <Row>{`${serviceCenter.name} -- ${serviceCenter.phone}`}</Row>}
                                {cashRegisterNum && <Row>{cashRegisterNum}</Row>}
                                {cashDeskStatus && <Row style={{fontSize: '18px', color: getStatusColor(cashDeskStatus)}}>{translateStatus(cashDeskStatus)}</Row>}
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={6} className="release-water">{hasReleaseWaterAccess && <Button onClick={handleReleaseWater}>Налити воду</Button>}</Col>
                </Row>
                <Row>
                    {currIndoorTemp !== undefined && hasMoneyAccess && <Col xs={12} style={{fontSize: '18px'}}><b>Гроші:</b> {cash} грн.</Col>}
                    {currBanknoteTemp !== undefined && <Col xs={12} style={{fontSize: '18px'}}><b>Вода:</b> {water} л.</Col>}
                </Row>
                <Row>
                    {currIndoorTemp !== undefined && <Col xs={12}><b>t <Icon type="hdd" /></b> = {currIndoorTemp} &deg;C</Col>}
                    {currBanknoteTemp !== undefined && <Col xs={12}><b>t <Icon type="dollar" /></b> = {currBanknoteTemp} &deg;C</Col>}
                </Row>
                <Row>
                    {energyCounter !== undefined && <Col xs={12}><b><Icon type="dashboard" /></b> {energyCounter} кВт</Col> }
                    {energyCounter !== undefined && <Col xs={12}><b><Icon type="wifi" /></b> {signalLevel}</Col> }
                </Row>
            </div>

            {Array.isArray(events) && events.length > 0 && (
                <div className={showEvents ? 'toggler-wrapper' : 'toggler-wrapper hide'}>
                    <div className={showEvents ? 'toggler' : 'toggler hide'} onClick={toggleShowEvents}>
                        <Icon type="notification" />
                        <span>{showEvents ? 'Сховати' : 'Показати'} повідомлення</span>
                        <Icon type={showEvents ? 'up' : 'down'} />
                    </div>
                </div>
            )}

            <div className={cn('automaton-events', { hide: !showEvents })}>
                {Array.isArray(events) && events.map(event => {
                    const { backgroundColor, fontColor } = resolveEventColor(event);

                    return (
                        <Row className={cn('event')} style={{ backgroundColor, color: fontColor }}>
                            <Row>Повідомлення: {event.message || event.description.text}</Row>
                            <Row>Код: {event.description.code}</Row>
                        </Row>
                    );
                })}
            </div>
        </article>
    );
};

AutomatonCard.propTypes = {
    automaton: automatonType.isRequired,
    releaseWater: pt.func.isRequired,
};

export default AutomatonCard;
