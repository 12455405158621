import { handleActions } from 'redux-actions';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        // FETCH_AUTOMATONS
        [TYPE.FETCH_AUTOMATONS_LOADING]: (state) => {
            return {
                ...state,
                loading: true
            };
        },
        [TYPE.FETCH_AUTOMATONS_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        },
        [TYPE.FETCH_AUTOMATONS_ERROR]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        },
        [TYPE.PREPARE_FILTER_SUCCESS]: (state, action) => {
            return {
                ...state,
                filterValues: action.payload
            };
        },

        // RELEASE_WATER
        [TYPE.RELEASE_WATER_LOADING]: (state) => {
            return {
                ...state,
                releaseWater: {
                    ...state.releaseWater,
                    loading: true
                }
            };
        },
        [TYPE.RELEASE_WATER_SUCCESS]: (state) => {
            return {
                ...state,
                releaseWater: {
                    ...state.releaseWater,
                    loading: false
                }
            };
        },

        //  TOGGLE MODAL
        [TYPE.TOGGLE_MODAL_SUCCESS]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                modals: {
                    ...state.modals,
                    [payload.modalName]: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            };
        },

        //  FILTER OFFLINE
        [TYPE.TOGGLE_FILTER_OFFLINE_SUCCESS]: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                filterOffline: payload.checked
            };
        },
    },
    {
        loading: false,
        data: [],
        filterValues: [],
        error: null,
        pagination: {
            size: 25,
            page: 0
        },
        filters: {
            searchQuery: '',
        },
        modals: {
            releaseWater: {
                active: false,
                data: {}
            }
        },
        releaseWater: {
            loading: false,
        },
        filterOffline: false
    }
);

export default reducer;
