import { useRef, useEffect, useCallback } from 'react';

export default ({ onLoadMore, loading, hasNextPage }) => {
    const scrollEl = useRef(null);

    const handleScroll = useCallback((e) => {
        const { target } = e;
        const currentScrollOffsetTop = target.clientHeight + target.scrollTop;

        // console.log(!loading, hasNextPage, target.scrollHeight - currentScrollOffset < 200);
        if (!loading
                && hasNextPage
                && target.scrollHeight - currentScrollOffsetTop < 200) {
            onLoadMore();
        }
    }, [loading, hasNextPage]);


    useEffect(() => {
        scrollEl.current.addEventListener('scroll', handleScroll);

        return () => {
            scrollEl.current.removeEventListener('scroll', handleScroll);
        };
    }, [scrollEl.current, loading, hasNextPage]);


    return scrollEl;
};
