import React from 'react';
import { Button } from 'antd';
import dispatch from 'app/dispatch';
import { ACTION } from 'app/ducks/cashiers_admin/actions';


export default [
    {
        title: 'Логін',
        dataIndex: 'login',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Імʼя',
        dataIndex: 'name',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Пін',
        dataIndex: 'pin',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Дії',
        dataIndex: 'login',
        key: 'actions',
        fixed: 'right',
        render: login => {
            return <Button onClick={() => dispatch(ACTION.edit({ new: false, login }))}>Редагувати</Button>;
        }
    },
];
