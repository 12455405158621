import React from 'react';
import { Button } from 'antd';
import dispatch from 'app/dispatch';
import { ACTION } from 'app/ducks/cash_desks_admin/actions';
import { getStatusColor, translateStatus } from '../../helpers';


export default [
    {
        title: 'РРО',
        dataIndex: 'cashRegisterNum',
        width: 150,
        fixed: 'left',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Назва',
        dataIndex: 'name',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Зміна',
        dataIndex: 'shiftStatus',
        render: text => <div className="cell"  style={{color: getStatusColor(text)}}>{translateStatus(text)}</div>,
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        render: text => <div className="cell" style={{color: getStatusColor(text)}}>{translateStatus(text)}</div>,
    },
    {
        title: 'ФОП',
        dataIndex: 'fop.name',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Касир',
        dataIndex: 'cashier.name',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Активна',
        dataIndex: 'enabled',
        render: active => <span className={active ? 'active' : 'inactive'}>{active ? 'Активна' : 'Неактивна'}</span>,
    },
    {
        title: 'Ключ ліцензії',
        dataIndex: 'licenseKey',
        width: 350,
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Дії',
        dataIndex: 'cashRegisterNum',
        key: 'cashRegisterNum',
        fixed: 'right',
        render: cashRegisterNum => {
            return <Button onClick={() => dispatch(ACTION.edit({ new: false, cashRegisterNum }))}>Редагувати</Button>;
        }
    },
];
