import React from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { Row, Col, Layout } from 'antd';

import { Login, Registration } from 'pages';

import './Auth.scss';

const { Content, Header } = Layout;

const Auth = () => (
    <Layout className="auth">
        <Header className="header">
            <Row type="flex" justify="end">
                <Col span={6}>
                    {/* Logo */}
                </Col>
                <Col span={18} className="header-links">
                    <NavLink to="/login">Вхід</NavLink>
                    {/* <NavLink to="/registration">Registration</NavLink> */}
                </Col>
            </Row>
        </Header>
        <Content className="content">
            <Switch>
                {/* index redirect to login page */}
                <Route exact path="/" render={() => <Redirect to="/login" />} />
                {/* routes */}
                <Route path="/login" component={Login} />
                {/* <Route path="/registration" component={Registration} /> */}
                {/* routes end */}
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Content>
    </Layout>
);

export default Auth;
