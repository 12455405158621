import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/cashiers_admin/actions';
import Cashiers from './Cashiers';

const getFilteredAdminCashiers = state => {
    return state.adminCashiers.data;
};

const enhancers = [
    connect(
        state => ({
            cashiers: state.adminCashiers,
            filteredCashiers: getFilteredAdminCashiers(state),
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Cashiers);
