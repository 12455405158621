import { all } from 'redux-saga/effects';

import authSagas from './auth/sagas';
import automatonsSagas from './automatons/sagas';
import mastersSagas from './masters/sagas';
import clientsSagas from './clients/sagas';
import transactionsSagas from './transactions/sagas';
import settingsSagas from './settings/sagas';
import events from './events/sagas';
import adminAutomatons from './automatons_admin/sagas';
import adminUsers from './users_admin/sagas';
import citiesAdmin from './cities_admin/sagas';
import adminServiceCenters from './service_centers_admin/sagas';
import adminFops from './fops_admin/sagas';
import adminCashDesks from './cash_desks_admin/sagas';
import adminCashiers from './cashiers_admin/sagas';
import reports from './reports/sagas';
import tax from './tax/sagas';

export default function* watchAll() {
    yield all([
        ...adminAutomatons,
        ...authSagas,
        ...automatonsSagas,
        ...mastersSagas,
        ...clientsSagas,
        ...transactionsSagas,
        ...settingsSagas,
        ...events,
        ...adminUsers,
        ...citiesAdmin,
        ...adminServiceCenters,
        ...adminFops,
        ...adminCashDesks,
        ...adminCashiers,
        ...reports,
        ...tax,
    ]);
}
