import React, { useEffect } from 'react';
import pt from 'prop-types';
import { Button, Col, Row } from 'antd';
import { Field } from 'redux-form';

import { remaindersReportColumns } from 'utils/tablesConfig';
import { Page, Filters, ReduxInputs, Table } from 'components/shared';

import './RemaindersReport.scss';

const RemaindersReport = (props) => {
    const { actions, remainders, filteredRemainders, totalRemainders, clientAmount } = props;
    const { fetchRemaindersReport, fetchTotalRemainders, exportToExcel } = actions;
    const { loading } = remainders;

    useEffect(() => {
        fetchRemaindersReport({ onMount: true });
        fetchTotalRemainders();
    }, []);

    return (
        <Page className="remainders-report wide-table">
            <div className="container">
                <Filters formId="remaindersReportFilters" initialValues={{ clientId: '' }}>
                    <Row>
                        <Field
                            name="client"
                            id="transactions-report-clients"
                            component={ReduxInputs.Text}
                            inputSpan={24}
                            placeholder="Номер рахунку"
                        />
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchRemaindersReport}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
                <Row>
                    <Col><b>Сума залишків:</b> {totalRemainders}</Col>
                </Row>
                <Row>
                    <Col><b>Кількість рахунків:</b> {clientAmount}</Col>
                </Row>
            </div>
            <Table
                rowKey="clientId"
                data={filteredRemainders}
                rowClassName={record => record.className}
                onFetch={fetchRemaindersReport}
                loading={loading}
                paginationPath="reports.remainders.pagination"
                columns={remaindersReportColumns}
            />
        </Page>
    );
};

RemaindersReport.propTypes = {
    actions: pt.shape({}).isRequired,
    remainders: pt.shape({}).isRequired,
    filteredRemainders: pt.arrayOf(pt.shape({})),
};

RemaindersReport.defaultProps = {
    filteredRemainders: [],
};

export default RemaindersReport;
