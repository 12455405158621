/* eslint-disable import/prefer-default-export */

export const PAYMENT_TYPES = {
    cashPayment: {
        value: 'CASH_PAYMENT',
        label: 'Оплата готівкою'
    },
    cardPayment: {
        value: 'CARD_PAYMENT',
        label: 'Оплата з рахунку'
    },
    cardRefill: {
        value: 'CARD_REFILL',
        label: 'Поповнення рахунку'
    },
    cashCollection: {
        value: 'CASH_COLLECTION',
        label: 'Інкасація'
    },
    waterRelease: {
        value: 'WATER_RELEASE',
        label: 'Наливання води'
    },
    waterRefill: {
        value: 'WATER_REFILL',
        label: 'Поповнення бочки'
    },
};

export const USER_TYPES = {
    master: {
        value: 'MASTER',
        label: 'Майстер',
    },
    admin: {
        value: 'ACCOUNT_ADMIN',
        label: 'Адміністратор',
    },
    operator: {
        value: 'OPERATOR',
        label: 'Оператор'
    },
    accountant: {
        value: 'ACCOUNTANT',
        label: 'Бухгалтер '
    }
};

export const ROLES = {
    ROLE_AUTOMATON_READ_MONEY: 'ROLE_AUTOMATON_READ_MONEY',
    ROLE_AUTOMATON_RELEASE_WATER: 'ROLE_AUTOMATON_RELEASE_WATER',
    ROLE_TRANSACTION_PAYMENT_READ: 'ROLE_TRANSACTION_PAYMENT_READ',
    ROLE_TRANSACTION_CLIENT_READ: 'ROLE_TRANSACTION_CLIENT_READ',
    ROLE_TRANSACTION_CASH_COLLECTION_READ: 'ROLE_TRANSACTION_CASH_COLLECTION_READ',
    ROLE_TRANSACTION_SERVICE_READ: 'ROLE_TRANSACTION_SERVICE_READ',
    ROLE_REPORTS_TURNOVER_READ: 'ROLE_REPORTS_TURNOVER_READ',
    ROLE_REPORTS_BALANCE_READ: 'ROLE_REPORTS_BALANCE_READ',
    ROLE_REPORTS_CLIENT_BALANCE_READ: 'ROLE_REPORTS_CLIENT_BALANCE_READ',
    ROLE_REPORTS_PRICE_HISTORY_READ: 'ROLE_REPORTS_PRICE_HISTORY_READ',
    ROLE_REPORTS_CASH_COLLECTION_READ: 'ROLE_REPORTS_CASH_COLLECTION_READ',
    ROLE_TAX_TRANSACTION_READ: 'ROLE_TAX_TRANSACTION_READ',
    ROLE_AUTOMATON_WRITE: 'ROLE_AUTOMATON_WRITE',
    ROLE_CITY_WRITE: 'ROLE_CITY_WRITE',
    ROLE_USERS_WRITE: 'ROLE_USERS_WRITE',
    ROLE_EVENTS_READ: 'ROLE_EVENTS_READ',
    ROLE_SERVICE_CENTER_WRITE: 'ROLE_SERVICE_CENTER_WRITE',
    ROLE_FOP_WRITE: 'ROLE_FOP_WRITE',
    ROLE_CASH_DESK_WRITE: 'ROLE_CASH_DESK_WRITE',
    ROLE_EVENTS_RESOLVE: "ROLE_EVENTS_RESOLVE",
}

export const NULL = 'no_value';
