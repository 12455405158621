import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Field } from 'redux-form';

import { collectionsTransactionsColumns } from 'utils/tablesConfig';
import { prepareAutomatonFilter, prepareMasterFilter } from 'utils/by-demand-data-fetch';
import { Page, Filters, ReduxInputs, Table } from 'components/shared';

import './CollectionsTransactions.scss';

const CollectionsTransactions = (props) => {
    const { actions, collections, filteredCollections, automatonFilterValues, mastersFilterValues } = props;
    const { fetchCollectionsTransactions, exportToExcel } = actions;
    const { loading } = collections;

    useEffect(() => {
        fetchCollectionsTransactions({ onMount: true });
        prepareAutomatonFilter();
        prepareMasterFilter();
    }, []);

    return (
        <Page className="collections wide-table">
            <div className="container">
                <Filters formId="collectionsTransactionsFilter">
                    <Row type="flex" justify="space-between">
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-collections-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="masters"
                                id="transactions-collections-masters"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Майстри"
                                mode="multiple"
                            >
                                {mastersFilterValues.map(master => (
                                    <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                        {master.firstName} {master.lastName}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Field
                            name="dateRange"
                            component={ReduxInputs.RangePicker}
                            placeholder={['Від', 'До']}
                            id="transactions-collections-collectionsDateRange"
                            onFocus={e => e.preventDefault()}
                            onBlur={e => e.preventDefault()}
                            inputSpan={24}
                        />
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchCollectionsTransactions}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>

            <Table
                rowKey="id"
                data={filteredCollections}
                loading={loading}
                onFetch={fetchCollectionsTransactions}
                paginationPath="transactions.collections.pagination"
                columns={collectionsTransactionsColumns}
            />
        </Page>
    );
};

export default CollectionsTransactions;
