import { constants, actions } from 'ducks-helpers';

//  SUFFIXES
// [
//   'LOADING',
//   'PENDING',
//   'SUCCESS',
//   'ERROR',
//   'FAILED',
//   'CANCELED'
// ]

export const TYPE = constants('events', [
    '~FETCH_EVENTS',

    '~TOGGLE_MODAL',

    '~RESOLVE_EVENT',
]);

export const ACTION = actions(TYPE);
