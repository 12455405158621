/* eslint-disable arrow-body-style */
import { handleActions } from 'redux-actions';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        [TYPE.FETCH_PRICES_LOADING]: (state) => {
            return {
                ...state,
                loading: true
            };
        },
        [TYPE.FETCH_PRICES_SUCCESS]: (state, action) => {
            const { content, first, last, size, number } = action.payload;
            return {
                ...state,
                data: content,
                pagination: {
                    first,
                    last,
                    size,
                    page: number
                },
                loading: false
            };
        },
        [TYPE.FETCH_PRICES_ERROR]: (state, action) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        },

        [TYPE.EDIT]: (state, action) => {
            return {
                ...state,
                modals: {
                    editPrice: {
                        active: true,
                        data: action.payload
                    }
                }
            };
        },

        [TYPE.TOGGLE_EDIT_MODAL]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                modals: {
                    editPrice: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            };
        },
    },
    {
        loading: false,
        data: [],
        error: null,
        pagination: {
            first: true,
            last: false,
            size: 30,
            page: 0
        },
        modals: {
            editPrice: {
                active: false,
                data: {},
            },
        },
    }
);

export default reducer;
