import { constants, actions } from 'ducks-helpers';

//  SUFFIXES
// [
//   'LOADING',
//   'PENDING',
//   'SUCCESS',
//   'ERROR',
//   'FAILED',
//   'CANCELED'
// ]

export const TYPE = constants('automatons', [
    '~FETCH_AUTOMATONS',
    '~TOGGLE_MODAL',
    '~TOGGLE_FILTER_OFFLINE',
    '~RELEASE_WATER',
    '~PREPARE_FILTER'
]);

export const ACTION = actions(TYPE);
