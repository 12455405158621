import React from 'react';
import { Checkbox, Button } from 'antd';
import dispatch from 'app/dispatch';
import { ACTION } from 'app/ducks/users_admin/actions';
import { getTime } from 'utils/helpers';
import { AutomatonNameCell } from 'components/shared';


export default [
    {
        title: 'ID автомата',
        dataIndex: 'automaton.id',
        key: 'automatonId',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Назва автомата',
        dataIndex: 'automaton',
        key: 'automaton.name',
        render: automaton => <AutomatonNameCell automaton={automaton} />,
    },
    {
        title: 'Користувач',
        dataIndex: 'user.firstName',
        render: text => <div className="cell">{text || '---'}</div>,
    },
    {
        title: 'Ціна за літр, грн',
        dataIndex: 'price',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Дата',
        dataIndex: 'date',
        render: isoTime => {
            const [date, time] = getTime(isoTime);
            return <div className="cell">{date} {time}</div>;
        }
    },
];
