import { put, call, takeLatest, select } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { request } from 'api';
import _ from 'lodash';

import { getFilterParams, showMessage } from 'utils/helpers';
import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* fetchBalancesReportHandler() {
    try {
        const filters = yield select(selectors.filters);

        yield put(ACTION.fetchBalancesReportLoading());

        const res = yield call(request, {
            url: 'reports/balance',
            params: getFilterParams(filters)
        });

        if (_.size(res.data.reports)) {
            res.data.reports.push({
                automatonId: 'Сума:',
                automaton: '---',
                balance: res.data.totalBalance,
            });
        }

        yield put(ACTION.fetchBalancesReportSuccess(_.get(res, 'data.reports')));
    } catch (err) {
        yield put(ACTION.fetchBalancesReportError(err));
        console.error(err);
    }
}

function* exportToExcel() {
    try {
        const filters = yield select(selectors.filters);
        showMessage({ type: 'warning', message: 'Почалося завантаження звіту - це може зайняти деякий час.' });

        const res = yield call(request, {
            responseType: 'arraybuffer',
            url: '/reports/balance/export',
            params: getFilterParams(filters),
        });

        const contentDisposition = _.get(res, 'headers.content-disposition', 'filename=report.xls');
        const [, filename] = contentDisposition.match(/filename=(.*)/);

        const blob = new Blob([res.data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, filename);
    } catch (err) {
        console.log(err);
    }
}

export default [
    takeLatest(TYPE.FETCH_BALANCES_REPORT, fetchBalancesReportHandler),
    takeLatest(TYPE.EXPORT_TO_EXCEL, exportToExcel),
];
