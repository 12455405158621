import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/service_centers_admin/actions';
import ServiceCenters from './ServiceCenters';

const getFilteredAdminServiceCenters = state => {
    return state.adminServiceCenters.data;
};

const enhancers = [
    connect(
        state => ({
            serviceCenters: state.adminServiceCenters,
            filteredServiceCenters: getFilteredAdminServiceCenters(state)
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(ServiceCenters);
