import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import pt from 'prop-types';

import AuthzService from 'services/authorization';
import { ROLES } from 'app/constants';
import { ACTION as AUTH_ACTION } from 'app/ducks/auth/actions';
import MenuItem from './MenuItem';

import './AppHeader.scss';

const AppHeader = ({ logout }) => {
    const authzService = React.useContext(AuthzService.context);

    const permissions = useMemo(() => {
        return {
            //  transactions
            hasTransactionPaymentAccess: authzService.hasAccess({ role: ROLES.ROLE_TRANSACTION_PAYMENT_READ }),
            hasTransactionClientAccess: authzService.hasAccess({ role: ROLES.ROLE_TRANSACTION_CLIENT_READ }),
            hasTransactionCollectionAccess: authzService.hasAccess({ role: ROLES.ROLE_TRANSACTION_CASH_COLLECTION_READ }),
            hasTransactionServiceAccess: authzService.hasAccess({ role: ROLES.ROLE_TRANSACTION_SERVICE_READ }),
            hasTaxTransactionAccess: authzService.hasAccess({ role: ROLES.ROLE_TAX_TRANSACTION_READ }),

            //  reports
            hasReportsTurnoverAccess: authzService.hasAccess({ role: ROLES.ROLE_REPORTS_TURNOVER_READ }),
            hasReportsBalanceAccess: authzService.hasAccess({ role: ROLES.ROLE_REPORTS_BALANCE_READ }),
            hasReportsClientBalanceAccess: authzService.hasAccess({ role: ROLES.ROLE_REPORTS_CLIENT_BALANCE_READ }),
            hasReportsPriceHistoryAccess: authzService.hasAccess({ role: ROLES.ROLE_REPORTS_PRICE_HISTORY_READ }),
            hasReportsCashCollectionAccess: authzService.hasAccess({ role: ROLES.ROLE_REPORTS_CASH_COLLECTION_READ }),

            //  events
            hasEventsAccess: authzService.hasAccess({ role: ROLES.ROLE_EVENTS_READ }),
            hasEventsResolveAccess: authzService.hasAccess({ role: ROLES.ROLE_EVENTS_RESOLVE }),

            //  admin
            hasAdminAutomatonAccess: authzService.hasAccess({ role: ROLES.ROLE_AUTOMATON_WRITE }),
            hasAdminCityAccess: authzService.hasAccess({ role: ROLES.ROLE_CITY_WRITE }),
            hasAdminUserAccess: authzService.hasAccess({ role: ROLES.ROLE_USERS_WRITE }),
            hasAdminServiceCenterAccess: authzService.hasAccess({ role: ROLES.ROLE_SERVICE_CENTER_WRITE }),
            hasAdminFopAccess: authzService.hasAccess({ role: ROLES.ROLE_FOP_WRITE }),
            hasAdminCashDeskAccess: authzService.hasAccess({ role: ROLES.ROLE_CASH_DESK_WRITE }),
            hasAdminCashiers: authzService.hasAccess({ role: ROLES.ROLE_CASH_DESK_WRITE }),

        }
    }, [authzService]);

    const showTransactionsTab = permissions.hasTransactionPaymentAccess
        || permissions.hasTransactionClientAccess
        || permissions.hasTransactionCollectionAccess
        || permissions.hasTransactionServiceAccess;
    const showReportsTab = permissions.hasReportsTurnoverAccess
        || permissions.hasReportsBalanceAccess
        || permissions.hasReportsClientBalanceAccess
        || permissions.hasReportsPriceHistoryAccess
        || permissions.hasReportsCashCollectionAccess;
    const showEventsTab = permissions.hasEventsAccess;
    const showTaxTab = permissions.hasTaxTransactionAccess;
    const showAdminTab = permissions.hasAdminAutomatonAccess
        || permissions.hasAdminCityAccess
        || permissions.hasAdminUserAccess
        || permissions.hasAdminServiceCenterAccess
        || permissions.hasAdminFopAccess
        || permissions.hasAdminCashDeskAccess;

    return (
        <header className="container app-header">
            <nav className="menu">
                <div className="left">
                    <MenuItem to="/automatons" text="Статус автоматів" />
                    {showTransactionsTab && <MenuItem to="/transactions" text="Транзакції">
                        {permissions.hasTransactionPaymentAccess && <MenuItem.Item text="Оплати" to="/payments" />}
                        {permissions.hasTransactionClientAccess && <MenuItem.Item text="По рахунках" to="/by-accounts" />}
                        {permissions.hasTransactionCollectionAccess && <MenuItem.Item text="Інкасації" to="/collections" />}
                        {permissions.hasTransactionServiceAccess && <MenuItem.Item text="Сервіс" to="/services" />}
                    </MenuItem>}

                    {showReportsTab && <MenuItem to="/reports" text="Бухгалтерія">
                        {permissions.hasReportsTurnoverAccess && <MenuItem.Item text="Оборот" to="/transactions" />}
                        {permissions.hasReportsBalanceAccess && <MenuItem.Item text="Баланс" to="/balances" />}
                        {permissions.hasReportsCashCollectionAccess && <MenuItem.Item text="Інкасації" to="/cash-collection" />}
                        {permissions.hasReportsClientBalanceAccess && <MenuItem.Item text="Залишки по рахунках" to="/remainders" />}
                        {permissions.hasReportsPriceHistoryAccess && <MenuItem.Item text="Історія цін" to="/prices-history" />}
                    </MenuItem>}

                    {showTaxTab && <MenuItem to="/tax" text="Чеки" />}

                    {showEventsTab && <MenuItem to="/events" text="Події" />}

                    {showAdminTab && (
                        <MenuItem to="/admin" text="Адмін">
                            {permissions.hasAdminAutomatonAccess && <MenuItem.Item text="Автомати" to="/automatons" />}
                            {permissions.hasAdminCityAccess && <MenuItem.Item text="Міста" to="/cities" />}
                            {permissions.hasAdminUserAccess && <MenuItem.Item text="Користувачі" to="/users" />}
                            {permissions.hasAdminServiceCenterAccess && <MenuItem.Item text="Сервісні центри" to="/service-center" />}
                            {permissions.hasAdminFopAccess && <MenuItem.Item text="ФОП" to="/fops" />}
                            {permissions.hasAdminCashDeskAccess && <MenuItem.Item text="Каси" to="/cash-desks" />}
                            {permissions.hasAdminCashiers && <MenuItem.Item text="Касири" to="/cashiers" />}
                        </MenuItem>
                    )}
                </div>
                <div className="right">
                    <MenuItem to="/logout" text="Вийти" onClick={logout} />
                </div>
            </nav>
        </header>
    );
};

AppHeader.propTypes = {
    logout: pt.func.isRequired
};

export default connect(
    () => ({

    }),
    dispatch => ({
        logout: payload => dispatch(AUTH_ACTION.logout(payload))
    })
)(AppHeader);
