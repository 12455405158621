/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Spin, Row, Col, Button, Switch } from 'antd';
import { Field } from 'redux-form';

import { Page, Modal, ReduxInputs, Filters, NoData } from 'components/shared';
import { AutomatonCard } from 'components/Automatons';
import { prepareCityFilter, prepareMasterFilter, prepareAutomatonFilter, prepareServiceCenterFilter } from 'utils/by-demand-data-fetch';

import './Automatons.scss';

const UPDATE_INTERVAL = 5;

const Automatons = (props) => {
    const {
        actions,
        modals,
        automatons,
        filteredAutomatons,
        citiesFilterValues,
        mastersFilterValues,
        automatonFilterValues,
        filterOffline,
    } = props;
    const { fetchAutomatons, toggleModal, toggleFilterOffline, releaseWater: releaseWaterAction } = actions;

    useEffect(() => {
        fetchAutomatons();
        prepareAutomatonFilter();
        prepareCityFilter();
        prepareMasterFilter();

        const interval = setInterval(
            () => fetchAutomatons({ intervalUpdate: true }),
            UPDATE_INTERVAL * 1000
        );

        return () => clearInterval(interval);
    }, []);

    const openModal = (id) => {
        toggleModal({
            modalName: 'releaseWater',
            active: true,
            data: { automatonId: id }
        });
    };

    const closeModal = () => {
        toggleModal({ modalName: 'releaseWater', active: false });
    };

    console.log(filteredAutomatons);
    return (
        <Page className="automatons">
            <Modal
                formId="releaseWater"
                title="Налити воду"
                visible={modals.releaseWater.active}
                onCancel={closeModal}
                onOk={releaseWaterAction}
            >
                <Field
                    component={ReduxInputs.Text}
                    label="Кількість води"
                    name="amount"
                    id="amoutOfWater"
                />
            </Modal>

            <div className="container">
                <Filters formId="automatonsFilters" initialValues={{ cities: [], automatons: [], masters: [] }}>
                    <Row type="flex" justify="space-between">
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="masters"
                                id="filter-adutomaton-masters"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Майстри"
                                mode="multiple"
                            >
                                {mastersFilterValues.map(master => (
                                    <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                        {master.firstName} {master.lastName}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="filter-adutomaton-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="cities"
                                id="filter-adutomaton-city"
                                component={ReduxInputs.Select}
                                placeholder="Міста"
                                inputSpan={24}
                                mode="multiple"
                            >
                                {citiesFilterValues.map(city => (
                                    <ReduxInputs.Select.Option key={city.id} value={city.id}>
                                        {city.name}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-between">
                        <Col xs={24} sm={{ span: 11 }}>
                            <Field
                                name="address"
                                component={ReduxInputs.Text}
                                id="filter-automaton-address"
                                placeholder="Адреса"
                                inputSpan={24}
                            />
                        </Col>
                        <Col xs={24} sm={{ span: 11 }}>
                            <Field
                                name="simId"
                                component={ReduxInputs.Text}
                                id="filter-automaton-address"
                                placeholder="SIM ID"
                                inputSpan={24}
                            />
                        </Col>
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchAutomatons}><span>Застосувати фільтри</span></Button>
                    </div>
                </Filters>
                <Row>
                    <Col>Приховати оффлайн <Switch onChange={toggleFilterOffline} checked={filterOffline}/></Col>
                </Row>
            </div>

            <div className="automatons-list">
                {automatons.loading ? (
                    <Spin className="spin" />
                ) : Array.isArray(filteredAutomatons) && filteredAutomatons.length > 0
                    ? filteredAutomatons.map(automaton => <AutomatonCard key={automaton.id} automaton={automaton} releaseWater={openModal} />)
                    : <NoData />
                }
            </div>
        </Page>
    );
};

export default Automatons;
