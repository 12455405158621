import { handleActions } from 'redux-actions';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        // FETCH_TRANSACTIONS_REPORT
        [TYPE.FETCH_TRANSACTIONS_REPORT_LOADING]: (state) => {
            return {
                ...state,
                loading: true
            };
        },
        [TYPE.FETCH_TRANSACTIONS_REPORT_SUCCESS]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        },
        [TYPE.FETCH_TRANSACTIONS_REPORT_ERROR]: (state, action) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        },
    },
    {
        loading: false,
        data: [],
        error: null,
        pagination: {
            size: 25,
            page: 0
        },
        filters: {
            startDate: '',
            endDate: '',
            type: '',
            terminal: '',
            searchQuery: '',
        },
    }
);

export default reducer;
