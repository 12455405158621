import { constants, actions } from 'ducks-helpers';

//  SUFFIXES
// [
//   'LOADING',
//   'PENDING',
//   'SUCCESS',
//   'ERROR',
//   'FAILED',
//   'CANCELED'
// ]

export const TYPE = constants('auth', [
    '~LOGIN',
    '~LOGOUT',
    '~RESET_LOGIN'
]);

export const ACTION = actions(TYPE);
