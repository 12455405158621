import React, { useEffect, useCallback, useMemo } from 'react';
import pt from 'prop-types';
import { Button } from 'antd';
import { Field } from 'redux-form';
import get from 'lodash/get';

import { adminCashiersColumns } from 'utils/tablesConfig';
import validators from 'utils/redux-form/validators';
import { Page, ReduxModalForm, ReduxInputs, Table } from 'components/shared';

import './Cashiers.scss';

const Cashiers = (props) => {
    const { actions, cashiers, filteredCashiers } = props;
    const { fetchCashiers, toggleEditModal, submitEdit, edit } = actions;
    const { loading, modals } = cashiers;

    useEffect(() => {
        fetchCashiers();
    }, []);

    const getEditFormIntialValues = useCallback((modalPayload) => {
        if (modalPayload.new) {
            return {
                login: '',
                name: '',
                pin: '',
            };
        }
        return {
            login: modalPayload.login,
            name: modalPayload.name,
            pin: modalPayload.pin,
        };
    }, []);

    const openCreateModal = useCallback(() => {
        edit({ new: true });
    }, []);

    const newModalOpen = useMemo(() => !!get(modals, 'editCashier.data.new'), [modals]);

    return (
        <Page className="cashiers-admin wide-table">
            <ReduxModalForm
                formId="adminCashierEdit"
                title={newModalOpen ? 'Створення касира' : 'Редагування касира'}
                submitButtonText={newModalOpen ? 'Створити' : 'Редагувати'}
                optionsPath="adminCashiers.modals.editCashier"
                getIntialValues={getEditFormIntialValues}
                toggler={toggleEditModal}
                onSubmit={submitEdit}
            >
                <Field
                    name="login"
                    component={ReduxInputs.Text}
                    id="cashier_login"
                    label="Логін"
                    disabled={!newModalOpen}
                    validate={[validators.required]}
                />
                <Field
                    name="name"
                    component={ReduxInputs.Text}
                    id="cashier_name"
                    label="Імʼя"
                    validate={[validators.required]}
                />
                <Field
                    name="pin"
                    component={ReduxInputs.Text}
                    id="cashier_name"
                    label="Пін код"
                    validate={[validators.required]}
                />
            </ReduxModalForm>

            <div className="container">
                <Button onClick={openCreateModal} style={{ marginBottom: '20px' }}>Додати касира</Button>
            </div>

            <Table
                rowKey="login"
                rowClassName={record => record.className}
                data={filteredCashiers}
                loading={loading}
                paginationPath={false}
                columns={adminCashiersColumns}
            />
        </Page>
    );
};

Cashiers.propTypes = {
    actions: pt.shape({}).isRequired,
};

Cashiers.defaultProps = {
};

export default Cashiers;
