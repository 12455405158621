import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import dispatch from 'app/dispatch';
import { ACTION } from 'app/ducks/users_admin/actions';
import { translateUserType } from 'utils/helpers';


export default [
    {
        title: 'Логін',
        dataIndex: 'username',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Ім\'я',
        render: user => <div className="cell">{user.firstName}{user.lastName && ` ${user.lastName}`}</div>,
    },
    {
        title: 'Ролі',
        dataIndex: 'userType',
        render: userType => <div className="cell">{translateUserType(userType)}</div>,
    },
    {
        title: 'Активний',
        dataIndex: 'enabled',
        render: active => <span className={active ? 'active' : 'inactive'}>{active ? 'Активний' : 'Неактивний'}</span>,
    },
    {
        title: 'Дії',
        dataIndex: 'id',
        key: 'id',
        render: id => {
            return <Button onClick={() => dispatch(ACTION.edit({ new: false, id }))}>Редагувати</Button>;
        }
    },
];
