import moment from 'moment';

export const formatDatePicker = date => {
    if (typeof date === 'string') {
        return moment(date);
    }
    return date;
};

export const someAnother = () => {

};
