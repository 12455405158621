import get from 'lodash/get';

export const filters = state => get(state, 'form.adminAutomatonsFilters.values');

export const getAutomatonById = id => state => {
    const automatons = get(state, 'adminAutomatons.data', []);
    return automatons.find((a) => a.id === id);
};

export const getModalData = modalName => state => get(state, `adminAutomatons.modals.${modalName}.data`, {});

export const adminAutomatons = state =>  get(state, 'adminAutomatons.data', []);