/* eslint-disable arrow-body-style */

import { notification } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import tinyColor from 'tinycolor2';

import { USER_TYPES, PAYMENT_TYPES, NULL } from 'app/constants';

// eslint-disable-next-line import/prefer-default-export
export const padStartDate = (date) => {
    return String(date).padStart(2, '0');
};

export const getTime = timeStamp => {
    if (!timeStamp) {
        return ['---'];
    }
    const date = new Date(timeStamp);
    const day = padStartDate(date.getDate());
    const month = padStartDate(date.getMonth() + 1);
    const year = padStartDate(date.getFullYear());
    const hours = padStartDate(date.getHours());
    const minutes = padStartDate(date.getMinutes());
    return [`${day}.${month}.${year}`, `${hours}:${minutes}`];
};

export const createFormData = obj => {
    const formData = new FormData();

    Object.entries(obj).forEach(([key, value]) => {
        formData.append(key, value);
    });

    return formData;
};

export const isObject = payload => typeof payload === 'object' && payload !== null;

export const filterByArray = (sourceArr, searchArray, predicate) => {
    return sourceArr.filter(sourceVal => {
        return searchArray.reduce((res, type) => {
            return res || predicate(sourceVal, type);
        }, false);
    });
};

export const showMessage = ({ message, description, type = 'error' }) => {
    const key = _.random(1e4);
    notification[type]({
        message,
        description,
        key,
        onClick: () => notification.close(key)
    });
};

export const merge = (...sources) => {
    return _.mergeWith({}, ...sources, (obj, src) => {
        return src;
    });
};

export const translateTransationType = type => {
    const translation = _.find(PAYMENT_TYPES, {
        value: type
    }) || {};

    return translation.label || type;
};

export const convertTransactionToType = transaction => {
    const { type, clientId } = transaction;
    const translatedType = translateTransationType(type)
    if ([PAYMENT_TYPES.cardPayment.value, PAYMENT_TYPES.cardRefill.value].includes(type)) {
        return `${translatedType} -- ${clientId}`;
    }
    return translatedType;
};

export const translateUserType = type => {
    const translation = _.find(USER_TYPES, {
        value: type
    }) || {};

    return translation.label || type;
};

export const getFilterParams = (filterFormValues = {}, extend = _.noop) => {
    const filters = _.cloneDeep(filterFormValues);

    const params = {};
    if (_.size(filters.automatons)) {
        params.automatonIds = filters.automatons.join(',');
    }
    if (_.size(filters.cities)) {
        params.cityIds = filters.cities.join(',');
    }
    if (_.size(filters.masters)) {
        params.masterIds = filters.masters.join(',');
    }
    if (_.size(filters.client)) {
        params.clientId = filters.client;
    }
    if (_.size(filters.transactionType) && filters.transactionType !== NULL) {
        params.type = filters.transactionType;
    }
    if (_.size(filters.transactionTypes) && filters.transactionTypes !== NULL) {
        params.types = filters.transactionTypes;
    }
    if (_.size(filters.users)) {
        params.userIds = filters.users.join(',');
    }
    if (_.size(filters.collectors)) {
        params.collectorIds = filters.collectors.join(',');
    }
    if (filters.address) {
        params.address = filters.address;
    }
    if (filters.simId) {
        params.simId = filters.simId;
    }
    if (filters.price) {
        params.price = filters.price;
    }
    if (filters.cashRegisterNum) {
        params.cashRegisterNum = filters.cashRegisterNum;
    }
    if (filters.id) {
        params.id = filters.id;
    }
    if (filters.cashDesks) {
        params.cashRegisterNums = filters.cashDesks.join(',');
    }
    if (filters.fops) {
        params.fopIds = filters.fops.join(',');
    }

    if (_.size(filters.dateRange)) {
        if (moment.isMoment(filters.dateRange[0])) {
            params.dateStart = filters.dateRange[0].format();
        }
        if (moment.isMoment(filters.dateRange[1])) {
            params.dateEnd = filters.dateRange[1].format();
        }
    }
    if (moment.isMoment(filters.date)) {
        params.date = filters.date.format();
    }

    extend(params);
    return params;
};

export const isJSON = (data) => {
    try {
        if (data.constructor === FormData) {
            return false;
        }
        JSON.stringify(data);
        return true;
    } catch (err) {
        return false;
    }
};

export const resolveEventColor = (event) => {
    let backgroundColor = 'yellow';
    let fontColor;

    const eventColor = (event.description && event.description.color) || 'yellow';
    const tinyColorInst = tinyColor(eventColor);
    if (tinyColorInst.isValid()) {
        backgroundColor = eventColor;
        const br = tinyColorInst.getBrightness();
        if (br < 50) {
            fontColor = '#fff';
        }
    }

    return { backgroundColor, fontColor };
};

export function translateStatus(status) {
    switch (status) {
        case 'OPEN':
            return 'Відкрита'
        case 'OPENED':
            return 'Відкрита'
        case 'CREATED':
            return 'Відкривається'
        case 'CLOSE':
            return 'Закрита'
        case 'CLOSING':
            return 'Закривається'
        case 'CLOSED':
            return 'Закрита'
        case 'ERROR':
            return 'Помилка'
        case 'GLOBAL_ERROR':
            return 'Глобальна Помилка'
        case 'OFFLINE':
            return 'Фіскальний сервер оффлайн'
        case 'OPENING':
            return 'Перевідкриття'
        case 'MISSING':
            return 'Відсутня'
        case 'OPERATIVE':
            return 'Відкрита'
        case 'OPERATIVE_OFFLINE':
            return 'Відкрита оффлайн'
        default:
            return status
    }
}

export function getStatusColor(status) {
    switch (status) {
        case 'OPEN':
            return 'green'
        case 'OPENED':
            return 'green'
        case 'CLOSE':
            return 'orange'
        case 'CLOSED':
            return 'orange'
        case 'ERROR':
            return 'red'
        case 'GLOBAL_ERROR':
            return 'red'
        case 'OFFLINE':
            return 'red'
        case 'OPENING':
            return 'blue'
        case 'MISSING':
            return 'gray'
        case 'OPERATIVE':
            return 'green'
        case 'OPERATIVE_OFFLINE':
            return 'green'
        default:
            return 'black'
    }
}

export function convertTaxTransactionToLink(tr) {
    if (!tr.receiptUid) {
        return ''
    }

    return `https://check.checkbox.ua/${tr.receiptUid}`
}

export function getNonOccupiedCashiers(cashDesks, cashiers) {
    return cashiers.filter(cashier => !cashDesks.some(cd => cd.cashierLogin === cashier.login))
}
