import React, { useEffect } from 'react';
import pt from 'prop-types';
import { Button, Col, Row } from 'antd';
import { Field } from 'redux-form';

import { prepareAutomatonFilter, prepareCityFilter, prepareMasterFilter } from 'utils/by-demand-data-fetch';
import { balancesReportColumns } from 'utils/tablesConfig';
import { Page, Filters, ReduxInputs, Table } from 'components/shared';

import './BalancesReport.scss';

const BalancesReport = (props) => {
    const { actions, balances, filteredBalances, automatonFilterValues, mastersFilterValues, citiesFilterValues } = props;
    const { fetchBalancesReport, exportToExcel } = actions;
    const { loading } = balances;

    useEffect(() => {
        fetchBalancesReport();
        prepareAutomatonFilter();
        prepareMasterFilter();
        prepareCityFilter();
    }, []);

    return (
        <Page className="balances-report wide-table">
            <div className="container">
                <Filters formId="balancesReportFilters" initialValues={{ cities: [], automatons: [], masters: [], dataRange: null }}>
                    <Row type="flex" justify="space-between">
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="masters"
                                id="report-transactions-masters"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Майстри"
                                mode="multiple"
                            >
                                {mastersFilterValues.map(master => (
                                    <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                        {master.firstName} {master.lastName}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-report-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="cities"
                                id="report-transactions-city"
                                component={ReduxInputs.Select}
                                placeholder="Міста"
                                inputSpan={24}
                                mode="multiple"
                            >
                                {citiesFilterValues.map(city => (
                                    <ReduxInputs.Select.Option key={city.id} value={city.id}>
                                        {city.name}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="date"
                                component={ReduxInputs.DatePicker}
                                placeholder="Дата"
                                id="transactions-report-date"
                                onFocus={e => e.preventDefault()}
                                onBlur={e => e.preventDefault()}
                                inputSpan={24}
                            />
                        </Col>
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchBalancesReport}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>
            <Table
                rowKey="automatonId"
                data={filteredBalances}
                loading={loading}
                paginationPath={false}
                columns={balancesReportColumns}
            />
        </Page>
    );
};

BalancesReport.propTypes = {
    actions: pt.shape({}).isRequired,
    balances: pt.shape({}).isRequired,
    filteredBalances: pt.arrayOf(pt.shape({})),
};

BalancesReport.defaultProps = {
    filteredBalances: [],
};

export default BalancesReport;
