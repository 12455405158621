import get from 'lodash/get';

export const getFilters = state => state.form.automatonsFilters;

export const getUserById = id => state => {
    const users = get(state, 'adminUsers.data', []);
    return users.find((a) => a.id === id);
};

export const isNewModal = modalName => state => {
    return get(state, `adminUsers.modals.${modalName}.data.new`, false);
};

export const getModalData = modalName => state => get(state, `adminUsers.modals.${modalName}.data`, {});
