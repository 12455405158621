/* eslint-disable import/prefer-default-export */

export const sortAutomatons = (automatons) => {
    const getNumberRepresentation = (events) => {
        if (events) {
            return events.length;
        }
        return 0;
    };

    automatons.sort((aut1, aut2) => {
        const a = getNumberRepresentation(aut1.events);
        const b = getNumberRepresentation(aut2.events);

        if (a > b) {
            return -1;
        }
        if (a < b) {
            return 1;
        }

        return 0;
    });
};
