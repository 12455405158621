import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/transactions/payments/actions';
import PaymentsTransactions from './PaymentsTransactions';

const getFilteredPayments = state => {
    return state.transactions.payments.data;
};

const enhancers = [
    connect(
        state => ({
            payments: state.transactions.payments,
            filteredPayments: getFilteredPayments(state),
            automatonFilterValues: state.automatons.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(PaymentsTransactions);
