import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/cities_admin/actions';
import Cities from './Cities';

const getFilteredAdminAutomatons = state => {
    return state.adminCities.data;
};

const enhancers = [
    connect(
        state => ({
            cities: state.adminCities,
            filteredCities: getFilteredAdminAutomatons(state)
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Cities);
