/* eslint-disable arrow-body-style */
import { handleActions } from 'redux-actions';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        // FETCH_AUTOMATONS
        [TYPE.FETCH_AUTOMATONS_LOADING]: (state) => {
            return {
                ...state,
                loading: true
            };
        },
        [TYPE.FETCH_AUTOMATONS_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        },
        [TYPE.FETCH_AUTOMATONS_ERROR]: (state, action) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        },
        [TYPE.TOGGLE_EDIT_MODAL]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                modals: {
                    editAutomaton: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            };
        },
        [TYPE.TOGGLE_SET_MASTER_MODAL]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                modals: {
                    setMaster: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            };
        },
        [TYPE.TOGGLE_SET_PRICE_MODAL]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                modals: {
                    setPrice: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            };
        },
        [TYPE.TOGGLE_SET_TEMPERATURE_MODAL]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                modals: {
                    setTemperature: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            };
        },
        [TYPE.TOGGLE_OPEN_MODAL]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                modals: {
                    openAutomaton: {
                        active: payload.active,
                        data: payload.data
                    }
                }
            };
        },
        [TYPE.UPDATE_AUTOMATONS]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                data: payload.data
            };
        },
        [TYPE.SET_SELECTED_ALL]: (state, action) => {
            const { payload } = action;

            return {
                ...state,
                allSelected: payload.selected
            };
        },
    },
    {
        loading: false,
        data: [],
        error: null,
        allSelected: false,
        pagination: {
            size: 25,
            page: 0
        },
        modals: {
            editAutomaton: {
                active: false,
                data: {}
            },
            setPrice: {
                active: false,
                data: {}
            },
            setMaster: {
                active: false,
                data: {}
            },
            setTemperature: {
                active: false,
                data: {}
            },
            openAutomaton: {
                active: false,
                data: {}
            }
        },
    }
);

export default reducer;
