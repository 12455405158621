import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/automatons_admin/actions';
import AutomatonsAdmin from './AutomatonsAdmin';


const getFilteredAdminAutomatons = state => {
    return state.adminAutomatons.data;
};

const enhancers = [
    connect(
        state => ({
            adminAutomatons: state.adminAutomatons,
            filteredAdminAutomatons: getFilteredAdminAutomatons(state),
            automatonFilterValues: state.automatons.filterValues,
            mastersFilterValues: state.masters.filterValues,
            citiesFilterValues: state.adminCities.filterValues,
            serviceCentersFilterValues: state.adminServiceCenters.filterValues,
            cashDesksFilterValues: state.adminCashDesks.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(AutomatonsAdmin);
