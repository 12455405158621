import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/transactions/services/actions';
import ServicesTransactions from './ServicesTransactions';

const getFilteredServices = state => {
    return state.transactions.services.data;
};

const enhancers = [
    connect(
        state => ({
            services: state.transactions.services,
            filteredServices: getFilteredServices(state),
            automatonFilterValues: state.automatons.filterValues,
            userFilterValues: state.adminUsers.filterValues
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(ServicesTransactions);
