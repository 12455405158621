import { put, call, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { showMessage } from 'utils/helpers';

import { request } from 'api';
import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* fetchCashiersHandler() {
    try {
        yield put(ACTION.fetchCashiersLoading());

        const res = yield call(request, 'account-admin/cashier');

        yield put(ACTION.fetchCashiersSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchCashiersError(err));
        console.error('fetchCashiersHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'account-admin/cashier');
        const filtersData = _.get(res, 'data', []);
        yield put(ACTION.prepareFilterSuccess(filtersData));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

function* editCashierHandler({ payload }) {
    try {
        if (payload.new) {
            yield put(ACTION.toggleEditModal({ active: true, data: payload }));
        } else {
            const cashier = yield select(selectors.getCashierById(payload.login));

            if (!cashier) {
                throw new Error('Edit: Chosen cashier not found.');
            } else {
                yield put(ACTION.toggleEditModal({ active: true, data: cashier }));
            }
        }
    } catch (err) {
        console.error('editCashierHandler', err);
    }
}

function* submitEditHandler({ payload = {} }) {
    try {
        const body = {
            name: payload.name,
            pin: payload.pin,
            login: payload.login,
        };
        const isNew = yield select(selectors.isNewModal('editCashier'));

        if (isNew) {
            yield call(request, {
                method: 'POST',
                url: 'account-admin/cashier',
                data: body
            });
            showMessage({ message: 'Створено касира:', description: payload.name, type: 'success' });
        } else {
            const editedCashiers = yield select(selectors.getModalData('editCashiers'));
            if (!editedCashiers) {
                throw new Error('Failed to get modal data!');
            }
            body.id = editedCashiers.id;
            yield call(request, {
                method: 'PUT',
                url: `account-admin/cashier`,
                data: body
            });
            showMessage({ message: 'Відредаговано успішною', type: 'success' });
        }

        yield put(ACTION.toggleEditModal({ active: false, data: {} }));
        yield put(ACTION.fetchCashiers());
        yield put(ACTION.prepareFilter());
    } catch (err) {
        console.error('submitEditHandler', err);
    }
}

export default [
    takeLatest(TYPE.FETCH_CASHIERS, fetchCashiersHandler),
    takeLatest(TYPE.EDIT, editCashierHandler),
    takeLatest(TYPE.SUBMIT_EDIT, submitEditHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
];
