/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import React from 'react';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';

const initialState = {
    roles: [],
    scopes: [],
    userType: '',
    userName: '',
};

function log(message, type = 'log') {
    console[type](`[AuthorizationService] ${message}`);
}

class AuthorizationService {
    constructor(authzState) {
        const _authzState = _.cloneDeep(authzState);

        if (Array.isArray(_authzState.roles)) {
            this.roles = _authzState.roles;
        } else {
            log('Roles are not provided.', 'error');
            this.roles = [];
        }
        this.scopes = _authzState.scopes || [];
        this.userType = _authzState.userType || '';
        this.userName = _authzState.userName || '';

        this.enabled = false;
    }

    static updatePayload(newPayload) {
        AuthorizationService.payload = newPayload;
    }

    static registerTrigger(trigger) {
        AuthorizationService.trigger = trigger;
    }

    static update() {
        try {
            const jwt = localStorage.getItem('token');
            if (!jwt) {
                log('Token is not present. All data with limitted access is forbidden.');
                AuthorizationService.updatePayload(new AuthorizationService(initialState));
            } else {
                const jwtPayload = jwtDecode(jwt);

                const updatedAuthzService = new AuthorizationService({
                    roles: jwtPayload.authorities,
                    userType: jwtPayload.userType,
                    scopes: jwtPayload.scope,
                    userName: jwtPayload.user_name,
                });
                updatedAuthzService.enabled = true;
                AuthorizationService.updatePayload(updatedAuthzService);

                log('Successfully updated.');
            }
        } catch (err) {
            log('Failed to update AuthzService.', 'error');
            log(err, 'error');

            AuthorizationService.updatePayload(new AuthorizationService(initialState));
        }
        AuthorizationService.trigger();
    }

    hasAccess({ role, action, userType }) {
        if (!this.enabled) {
            log('Is not enabled yet!', 'error');
            return false;
        }
        if (!role && !action && !userType) {
            log('role or action or userType is required!', 'error');
            return false;
        }

        if (action && !this._hasScope(action)) {
            return false;
        }

        if (role && !this._hasRole(role)) {
            return false;
        }

        if (userType && !this._hasUserType(userType)) {
            return false;
        }
        return true;
    }

    _hasRole(testedRole) {
        return !!this.roles.find(r => r.role === testedRole);
    }

    _hasScope(testedAction) {
        return this.scopes.includes(testedAction);
    }

    _hasUserType(testedUserType) {
        if (_.isArray(testedUserType) && testedUserType.includes(this.userType))  {
            return true;
        }
        if (testedUserType == this.userType) {
            return true;
        }
        return false
    }
}

AuthorizationService.payload = {};
AuthorizationService.trigger = _.noop;
AuthorizationService.update();

AuthorizationService.context = React.createContext();
AuthorizationService.Provider = (props) => (
    <AuthorizationService.context.Provider {...props} value={AuthorizationService.payload} />
);

window.authz = AuthorizationService;

export default window.authz;
