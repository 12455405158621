import get from 'lodash/get';

export const getFilters = state => state.form.adminCashiersFilters;

export const getModalData = modalName => state => get(state, `adminCashiers.modals.${modalName}.data`, {});

export const getCashierById = login => state => {
    const cities = get(state, 'adminCashiers.data', []);
    return cities.find((a) => a.login === login);
};

export const isNewModal = modalName => state => {
    return get(state, `adminCashiers.modals.${modalName}.data.new`, false);
};
