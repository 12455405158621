import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Field } from 'redux-form';

import { PAYMENT_TYPES } from 'app/constants';
import { servicesTransactionsColumns } from 'utils/tablesConfig';
import { prepareAutomatonFilter, prepareUserFilter } from 'utils/by-demand-data-fetch';
import { Page, Filters, ReduxInputs, Table } from 'components/shared';

import './ServicesTransactions.scss';

const transactionFilterOptions = [PAYMENT_TYPES.waterRelease, PAYMENT_TYPES.waterRefill];

const ServicesTransactions = (props) => {
    const { actions, services, filteredServices, automatonFilterValues, userFilterValues } = props;
    const { fetchServicesTransactions, exportToExcel } = actions;
    const { loading } = services;

    useEffect(() => {
        fetchServicesTransactions({ onMount: true });
        prepareAutomatonFilter();
        prepareUserFilter();
    }, []);

    return (
        <Page className="services wide-table">
            <div className="container">
                <Filters formId="servicesTransactionsFilter">
                    <Row type="flex" justify="space-between">
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="users"
                                id="transactions-by-account-users"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Користувачі"
                                mode="multiple"
                            >
                                {userFilterValues.map(user => <ReduxInputs.Select.Option key={user.id} value={user.id}>{user.firstName}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-services-automatons"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="transactionType"
                                component={ReduxInputs.Select}
                                id="transactions-services-transactionType"
                                placeholder="Тип транзакції"
                                inputSpan={24}
                            >
                                {/* <ReduxInputs.Select.Option value={NULL}>Всі</ReduxInputs.Select.Option> */}
                                {transactionFilterOptions.map(({ label, value }) => <ReduxInputs.Select.Option value={value} key={value}>{label}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Field
                            name="dateRange"
                            component={ReduxInputs.RangePicker}
                            placeholder={['Від', 'До']}
                            id="transactions-services-date"
                            onFocus={e => e.preventDefault()}
                            onBlur={e => e.preventDefault()}
                            inputSpan={24}
                        />
                    </Row>

                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchServicesTransactions}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>

            <Table
                rowKey="id"
                data={filteredServices}
                loading={loading}
                onFetch={fetchServicesTransactions}
                paginationPath="transactions.services.pagination"
                columns={servicesTransactionsColumns}
            />
        </Page>
    );
};

export default ServicesTransactions;
