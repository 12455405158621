import { constants, actions } from 'ducks-helpers';

//  SUFFIXES
// [
//   'LOADING',
//   'PENDING',
//   'SUCCESS',
//   'ERROR',
//   'FAILED',
//   'CANCELED'
// ]

export const TYPE = constants('admin/users', [
    '~FETCH_USERS',
    '~PREPARE_FILTER',

    /** EDIT ACTIONS */
    'EDIT',
    'TOGGLE_EDIT_MODAL',
    'SUBMIT_EDIT',
    /** */
]);

export const ACTION = actions(TYPE);
