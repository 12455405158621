import React from 'react';
import pt from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Options, Transactions, Automatons, Reports, Events, Admin, Tax } from 'pages';
import { AppHeader } from 'components/shared';
import { ProtectedRoute } from 'navigation';

import './Application.scss';

const Application = props => {
    return (
        <React.Fragment>
            <AppHeader />
            <main className="app-body">
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/automatons" />} />
                    <ProtectedRoute path="/automatons" component={Automatons} />
                    <ProtectedRoute path="/transactions" component={Transactions} />
                    <ProtectedRoute path="/reports" component={Reports} />
                    <ProtectedRoute path="/options" component={Options} />
                    <ProtectedRoute path="/events" component={Events} />
                    <ProtectedRoute path="/tax" component={Tax} />
                    <ProtectedRoute path="/admin" component={Admin} />

                    <Route render={() => <Redirect to="/automatons" />} />
                </Switch>
            </main>
        </React.Fragment>
    );
};

Application.propTypes = {

};

export default Application;
