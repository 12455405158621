import React from 'react';

export default [
    {
        title: 'Номер рахунку',
        dataIndex: 'clientId',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Баланс, грн',
        dataIndex: 'balance',
        render: text => <div className="cell">{text}</div>,
    }
];
