import { put, call, takeLatest, select } from 'redux-saga/effects';
import _ from 'lodash';
import { showMessage } from 'utils/helpers';

import { request } from 'api';
import { TYPE, ACTION } from './actions';
import * as selectors from './selectors';

function* fetchServiceCentersHandler() {
    try {
        yield put(ACTION.fetchServiceCentersLoading());

        const res = yield call(request, 'account-admin/service-center');

        yield put(ACTION.fetchServiceCentersSuccess(res.data));
    } catch (err) {
        yield put(ACTION.fetchServiceCentersError(err));
        console.error('fetchServiceCentersHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'account-admin/service-center');
        const filtersData = _.get(res, 'data', []);
        yield put(ACTION.prepareFilterSuccess(filtersData));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

function* editServiceCenterHandler({ payload }) {
    try {
        if (payload.new) {
            yield put(ACTION.toggleEditModal({ active: true, data: payload }));
        } else {
            const serviceCenter = yield select(selectors.getServiceCenterById(payload.id));

            if (!serviceCenter) {
                throw new Error('Edit: Chosen serviceCenter not found.');
            } else {
                yield put(ACTION.toggleEditModal({ active: true, data: serviceCenter }));
            }
        }
    } catch (err) {
        console.error('editServiceCenterHandler', err);
    }
}

function* submitEditHandler({ payload = {} }) {
    try {
        const body = {
            name: payload.name,
            phone: payload.phone,
        };
        const isNew = yield select(selectors.isNewModal('editServiceCenter'));

        if (isNew) {
            yield call(request, {
                method: 'POST',
                url: 'account-admin/service-center',
                data: body
            });
            showMessage({ message: 'Створено сервісний центр:', description: payload.serviceCenterName, type: 'success' });
        } else {
            const editedServiceCenter = yield select(selectors.getModalData('editServiceCenter'));
            if (!editedServiceCenter) {
                throw new Error('Failed to get modal data!');
            }
            body.id = editedServiceCenter.id;
            yield call(request, {
                method: 'PUT',
                url: `account-admin/service-center`,
                data: body
            });
            showMessage({ message: 'Відредаговано успішною', type: 'success' });
        }

        yield put(ACTION.toggleEditModal({ active: false, data: {} }));
        yield put(ACTION.fetchServiceCenters());
        yield put(ACTION.prepareFilter());
    } catch (err) {
        console.error('submitEditHandler', err);
    }
}

export default [
    takeLatest(TYPE.FETCH_SERVICE_CENTERS, fetchServiceCentersHandler),
    takeLatest(TYPE.EDIT, editServiceCenterHandler),
    takeLatest(TYPE.SUBMIT_EDIT, submitEditHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
];
