import { put, takeEvery, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { authRequest } from 'api';
import authorisationService from 'services/authorization';
import { createFormData } from 'utils/helpers';
import { TYPE, ACTION } from './actions';

function* login(formData) {
    const { username, password } = formData.payload;

    yield put(ACTION.loginPending());

    try {
        const data = createFormData({
            grant_type: 'password',
            username,
            password
        });

        const res = yield call(authRequest, { url: 'oauth/token', method: 'post', data });

        localStorage.setItem('token', res.data.access_token);
        localStorage.setItem('refresh_token', res.data.refresh_token);
        authorisationService.update();

        yield put(ACTION.loginSuccess());
        yield put(replace('/'));
    } catch (err) {
        yield put(ACTION.loginError(err));
    }
}

function* logout() {
    try {
        yield put(ACTION.logoutSuccess());

        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        authorisationService.update();

        yield put(replace('/'));
    } catch (err) {
        yield put(ACTION.logoutError(err));
    }
}

export default [
    takeEvery(TYPE.LOGIN, login),
    takeEvery(TYPE.LOGOUT, logout),
];
