import React, { useEffect } from 'react';
import pt from 'prop-types';
import { Button, Col, Row } from 'antd';
import { Field } from 'redux-form';
import moment from 'moment';

import { prepareAutomatonFilter, prepareCityFilter, prepareMasterFilter } from 'utils/by-demand-data-fetch';
import { cashCollectionReportColumns } from 'utils/tablesConfig';
import { Filters, Page, ReduxInputs, Table } from 'components/shared';

import './CashCollectionReport.scss';

const CashCollectionReport = (props) => {
    const { actions, cashCollection, filteredCashCollection, automatonFilterValues, mastersFilterValues, citiesFilterValues } = props;
    const { fetchCashCollectionReport, exportToExcel } = actions;
    const { loading } = cashCollection;

    useEffect(() => {
        fetchCashCollectionReport();
        prepareAutomatonFilter();
        prepareMasterFilter();
        prepareCityFilter();
    }, []);

    return (
        <Page className="cash-collection-report wide-table">
            <div className="container">
                <Filters formId="cashCollectionReportFilters"
                         initialValues={{ cities: [], automatons: [], masters: [], dateRange: [moment().startOf('month'), moment().startOf('day')] }}>
                    <Row type="flex" justify="space-between">
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="masters"
                                id="report-transactions-masters"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Майстри"
                                mode="multiple"
                            >
                                {mastersFilterValues.map(master => (
                                    <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                        {master.firstName} {master.lastName}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="collectors"
                                id="report-transactions-masters"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Інкасатори"
                                mode="multiple"
                            >
                                {mastersFilterValues.map(master => (
                                    <ReduxInputs.Select.Option key={master.id} value={master.id}>
                                        {master.firstName} {master.lastName}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-report-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id}
                                                                                               value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={11} sm={{ span: 7 }}>
                            <Field
                                name="cities"
                                id="report-transactions-city"
                                component={ReduxInputs.Select}
                                placeholder="Міста"
                                inputSpan={24}
                                mode="multiple"
                            >
                                {citiesFilterValues.map(city => (
                                    <ReduxInputs.Select.Option key={city.id} value={city.id}>
                                        {city.name}
                                    </ReduxInputs.Select.Option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Field
                            name="dateRange"
                            component={ReduxInputs.RangePicker}
                            placeholder={['Від', 'До']}
                            id="transactions-report-date"
                            onFocus={e => e.preventDefault()}
                            onBlur={e => e.preventDefault()}
                            inputSpan={24}
                        />
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchCashCollectionReport}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>
            <Table
                rowKey="automatonId"
                data={filteredCashCollection}
                loading={loading}
                paginationPath={false}
                columns={cashCollectionReportColumns}
            />
        </Page>
    );
};

CashCollectionReport.propTypes = {
    actions: pt.shape({}).isRequired,
    cashCollection: pt.shape({}).isRequired,
    filteredCashCollection: pt.arrayOf(pt.shape({}))
};

CashCollectionReport.defaultProps = {
    filteredCashCollection: []
};

export default CashCollectionReport;
