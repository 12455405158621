import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { ACTION } from 'app/ducks/reports/transactions/actions';
import TransactionsReport from './TransactionsReport';


const getFilteredTransactionsReport = state => {
    return state.reports.transactions.data;
};

const enhancers = [
    connect(
        state => ({
            transactions: state.reports.transactions,
            filteredTransactions: getFilteredTransactionsReport(state),
            automatonFilterValues: state.automatons.filterValues,
            mastersFilterValues: state.masters.filterValues,
            citiesFilterValues: state.adminCities.filterValues,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(TransactionsReport);
