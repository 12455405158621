import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Field } from 'redux-form';

import { PAYMENT_TYPES, NULL } from 'app/constants';
import { byAccountsTransactionsColumns } from 'utils/tablesConfig';
import { prepareAutomatonFilter } from 'utils/by-demand-data-fetch';
import { Page, Filters, ReduxInputs, Table } from 'components/shared';

import './ByAccountTransactions.scss';

const transactionFilterOptions = [PAYMENT_TYPES.cardPayment, PAYMENT_TYPES.cardRefill];

const ByAccountTransactions = (props) => {
    const { actions, byAccounts, filteredByAccounts, automatonFilterValues } = props;
    const { fetchByAccountsTransactions, exportToExcel } = actions;
    const { loading } = byAccounts;

    useEffect(() => {
        fetchByAccountsTransactions({ onMount: true });
        prepareAutomatonFilter();
    }, []);

    return (
        <Page className="byAccounts wide-table">
            <div className="container">
                <Filters formId="byAccountsTransactionsFilter">
                    <Row type="flex" justify="space-between">
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="client"
                                id="transactions-report-client"
                                component={ReduxInputs.Text}
                                inputSpan={24}
                                placeholder="Номер рахунку"
                            />
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-report-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="transactionType"
                                component={ReduxInputs.Select}
                                id="transactions-report-transactionType"
                                placeholder="Тип транзакції"
                                inputSpan={24}
                            >
                                {/* <ReduxInputs.Select.Option value={NULL}>Всі</ReduxInputs.Select.Option> */}
                                {transactionFilterOptions.map(({ label, value }) => <ReduxInputs.Select.Option value={value} key={value}>{label}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Field
                            name="dateRange"
                            component={ReduxInputs.RangePicker}
                            placeholder={['Від', 'До']}
                            id="transactions-report-date"
                            onFocus={e => e.preventDefault()}
                            onBlur={e => e.preventDefault()}
                            inputSpan={24}
                        />
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchByAccountsTransactions}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>

            <Table
                rowKey="id"
                data={filteredByAccounts}
                loading={loading}
                onFetch={fetchByAccountsTransactions}
                paginationPath="transactions.byAccounts.pagination"
                columns={byAccountsTransactionsColumns}
            />
        </Page>
    );
};

export default ByAccountTransactions;
