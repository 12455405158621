import { put, call, takeEvery, takeLatest, select, all } from 'redux-saga/effects';
import _ from 'lodash';

import { request } from 'api';
import { getFilterParams } from 'utils/helpers';
import { TYPE, ACTION } from './actions';
import { sortAutomatons } from './helpers';
import * as selectors from './selectors';

function* fetchAutomatonsHandler({ payload = {} }) {
    try {
        const filters = yield select(selectors.filters);
        const filterOffline = yield select(selectors.filterOffline);

        if (!payload.intervalUpdate) {
            yield put(ACTION.fetchAutomatonsLoading());
        }

        const [automatonsRes, eventsRes] = yield all([
            call(request, {
                url: 'automatons',
                params: getFilterParams(filters)
            }),
            call(request, 'events/non-resolved')
        ]);

        const events = eventsRes.data;
        let automatons = automatonsRes.data.map(automaton => {
            const automatonEvents = events.filter(_event => automaton.id === _event.automatonId);

            if (automatonEvents.length > 0) {
                // eslint-disable-next-line no-param-reassign
                automaton.events = automatonEvents;
            }
            return automaton;
        });
        if (filterOffline) {
            automatons = automatons.filter(a => a.online)
        }

        sortAutomatons(automatons);

        yield put(ACTION.fetchAutomatonsSuccess(automatons));
    } catch (err) {
        yield put(ACTION.fetchAutomatonsError(err));
        console.error('fetchAutomatonsHandler', err);
    }
}

function* prepareFilter() {
    try {
        const res = yield call(request, 'automatons');
        const filtersData = _.get(res, 'data', []);
        yield put(ACTION.prepareFilterSuccess(filtersData));
    } catch (err) {
        console.error('Failed to prepare automaton filter');
    }
}

function* releaseWaterHandler() {
    try {
        yield put(ACTION.releaseWaterLoading());

        const water = yield select(state => _.get(state, 'form.releaseWater.values.amount'));
        const automatonId = yield select(state => _.get(state, 'automatons.modals.releaseWater.data.automatonId'));

        if (water && automatonId) {
            yield call(request, {
                url: 'automatons/release-water',
                method: 'post',
                data: { water: Number(water), automatonId }
            });

            yield put(ACTION.releaseWaterSuccess());
            yield put(ACTION.toggleModal({ modalName: 'releaseWater', active: false }));
            yield put(ACTION.fetchAutomatons());
        } else {
            yield put(ACTION.releaseWaterFailed());
        }
    } catch (err) {
        yield put(ACTION.releaseWaterError(err));
        console.error('releaseWaterHandler', err);
    }
}

function* toggleModalHandler({ payload }) {
    const { modalName, active, data = {} } = payload;
    yield put(ACTION.toggleModalSuccess({ modalName, active, data }));
}

function* toggleFilterOffline({ payload }) {
    yield put(ACTION.toggleFilterOfflineSuccess({ checked: payload }));
    yield put(ACTION.fetchAutomatons());
}

export default [
    takeEvery(TYPE.FETCH_AUTOMATONS, fetchAutomatonsHandler),
    takeLatest(TYPE.TOGGLE_MODAL, toggleModalHandler),
    takeLatest(TYPE.RELEASE_WATER, releaseWaterHandler),
    takeLatest(TYPE.PREPARE_FILTER, prepareFilter),
    takeLatest(TYPE.TOGGLE_FILTER_OFFLINE, toggleFilterOffline),
];
