/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import _ from 'lodash';

import { ACTION } from 'app/ducks/auth/actions';
import { isObject, isJSON } from 'utils/helpers';
import { store } from '../App';
import { makeRefresh } from './auth';

const defaultAxios = axios.create();


/**
 * @param {Object} axiosPayload - axios request options (can be string in case of simple GET)
 * @param {Boolean} axiosPayload.rawJson - custom option, don't format json data (e.g. keep null values)
 */
export const request = axiosPayload => {
    const token = localStorage.getItem('token');
    let requestPayload = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    if (typeof axiosPayload === 'string') {
        requestPayload.url = axiosPayload;
    } else if (isObject(axiosPayload)) {
        requestPayload = _.defaultsDeep(axiosPayload, requestPayload);

        const isJsonPayload = !requestPayload.data || isJSON(requestPayload.data);
        if (axiosPayload.rawJson && isJsonPayload) {
            requestPayload.transformRequest = [(data, headers) => {
                headers['Content-Type'] = 'application/json; charset=utf-8';
                headers['Accept'] = 'application/json';
                return JSON.stringify(data);
            }];
        }
    } else {
        throw new Error('Request argument should be type of String or Object');
    }

    return defaultAxios(requestPayload);
};

defaultAxios.interceptors.response.use(
    response => response,
    async error => {
        const { response, config } = error;
        const refreshToken = localStorage.getItem('refresh_token');

        if (response && response.status === 401) {
            if (refreshToken) {
                const { err } = await makeRefresh(refreshToken);
                if (err) {
                    console.error('Refresh failed. Logout.');
                    store.dispatch(ACTION.logout());
                    return Promise.reject(err);
                }

                const { url, method, data, params } = config;
                return request({ url, method, data, params, fromIntercepter: true });
            }
            console.error('There is no refresh token. Logout.');
            store.dispatch(ACTION.logout());
        }
        return Promise.reject(error);
    }
);

export const pagableRequest = ({ size = 30, page = 0, onMount }) => (requestOptions) => {
    if (onMount) {
        // eslint-disable-next-line no-param-reassign
        page = 0;
    }
    if (size && page > -1) {
        const pagableParams = { size, page };

        const payload = isObject(requestOptions)
            ? _.merge(requestOptions, { params: pagableParams })
            : { url: requestOptions, params: pagableParams };

        return request(payload);
    }
    return request(requestOptions);
};
