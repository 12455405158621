import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Field } from 'redux-form';

import { PAYMENT_TYPES, NULL } from 'app/constants';
import { paymentsTransactionsColumns } from 'utils/tablesConfig';
import { prepareAutomatonFilter } from 'utils/by-demand-data-fetch';
import { Page, Filters, ReduxInputs, Table } from 'components/shared';

import './PaymentsTransactions.scss';

const transactionFilterOptions = [PAYMENT_TYPES.cardPayment, PAYMENT_TYPES.cashPayment];

const PaymentsTransactions = (props) => {
    const { actions, payments, filteredPayments, automatonFilterValues } = props;
    const { fetchPaymentsTransactions, exportToExcel } = actions;
    const { loading } = payments;

    useEffect(() => {
        fetchPaymentsTransactions({ onMount: true });
        prepareAutomatonFilter();
    }, []);

    return (
        <Page className="payments wide-table">
            <div className="container">
                <Filters formId="paymentsTransactionsFilter" initialValues={{ automatons: [], transactionType: undefined }}>
                    <Row type="flex" justify="space-between">
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-payments-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="transactionType"
                                component={ReduxInputs.Select}
                                id="transactions-payments-transactionType"
                                placeholder="Тип транзакції"
                                inputSpan={24}
                            >
                                {transactionFilterOptions.map(({ label, value }) => <ReduxInputs.Select.Option key={value} value={value}>{label}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Field
                            name="dateRange"
                            component={ReduxInputs.RangePicker}
                            placeholder={['Від', 'До']}
                            id="transactions-payments-paymentsDateRange"
                            onFocus={e => e.preventDefault()}
                            onBlur={e => e.preventDefault()}
                            inputSpan={24}
                        />
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchPaymentsTransactions}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>

            <Table
                rowKey="id"
                data={filteredPayments}
                loading={loading}
                onFetch={fetchPaymentsTransactions}
                paginationPath="transactions.payments.pagination"
                columns={paymentsTransactionsColumns}
            />
        </Page>
    );
};

export default PaymentsTransactions;
