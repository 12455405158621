import React, { Children } from 'react';
import pt from 'prop-types';
import cn from 'classnames'

import './Page.scss';

const Page = props => {
    const { children: propsChildren, className } = props;

    const children = Children.toArray(propsChildren);

    return (
        <div className={cn('page', 'container', className)}>
            {children}
        </div>
    );
};

Page.propTypes = {
    className: pt.string,
    children: pt.node.isRequired,
};

Page.defaultProps = {
    className: '',
};

export default Page;
