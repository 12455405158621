import { handleActions } from 'redux-actions';

import { TYPE } from './actions';

const reducer = handleActions(
    {
        // FETCH_TRANSACTIONS
        [TYPE.CREATE_AUTOMATON_LOADING]: (state) => {
            return {
                ...state,
                loading: true
            };
        },
        [TYPE.CREATE_AUTOMATON_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false
            };
        },
        [TYPE.CREATE_AUTOMATON_ERROR]: (state, action) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: action.payload
            };
        },
    },
    {
        loading: false,
        data: [],
        error: null,
        pagination: {
            size: 25,
            page: 0
        },
        filters: {

        },
    }
);

export default reducer;
