import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';


export default () => {
    return (
        <Switch>
            <Route exact path="/options" render={() => <Redirect to="/options/technical" />} />
            <Route path="/options/technical" render={() => 'technical'} />
            <Route path="/options/accounting" render={() => 'accounting'} />
            <Route path="/options/staff" render={() => 'staff'} />
        </Switch>
    );
};
