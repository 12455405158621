import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import get from 'lodash/get';

import { ACTION } from 'app/ducks/automatons/actions';
import Automatons from './Automatons';

const getFilteredAutomatons = state => {
    let filteredAutomatons = get(state, 'automatons.data', []);

    filteredAutomatons = filteredAutomatons.filter(automaton => {
        const searchQuery = get(state, 'form.automatonsFilters.values.searchQuery');
        if (searchQuery) {
            const str = automaton.name.toLowerCase();
            const subStr = searchQuery.toLowerCase();
            return str.includes(subStr);
        }
        return state.automatons;
    });

    return filteredAutomatons;
};

const enhancers = [
    connect(
        state => ({
            automatons: state.automatons,
            filteredAutomatons: getFilteredAutomatons(state),
            modals: state.automatons.modals,
            automatonFilterValues: state.automatons.filterValues,
            mastersFilterValues: state.masters.filterValues,
            citiesFilterValues: state.adminCities.filterValues,
            filterOffline: state.automatons.filterOffline,
        }),
        dispatch => ({
            actions: bindActionCreators(ACTION, dispatch),
        })
    )
];

export default compose(...enhancers)(Automatons);
