import React from 'react';

import { getTime } from 'utils/helpers';
import { AutomatonNameCell } from 'components/shared';

export default [
    {
        title: 'ID автомата',
        dataIndex: 'automatonId',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Назва автомата',
        dataIndex: 'automaton',
        key: 'automaton.name',
        render: automaton => <AutomatonNameCell automaton={automaton} />,
    },
    {
        title: 'Найбільша, грн',
        dataIndex: 'biggest',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Остання інкасація',
        dataIndex: 'last',
        render: text => <div className="cell">{getTime(text).join(' ')}</div>,
    },
    {
        title: 'Кількість',
        dataIndex: 'amount',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Сума, грн',
        dataIndex: 'total',
        render: text => <div className="cell">{text}</div>,
    },
];
