import React, { useEffect, useCallback, useMemo } from 'react';
import pt from 'prop-types';
import { Button } from 'antd';
import { Field } from 'redux-form';
import get from 'lodash/get';

import { adminCitiesColumns } from 'utils/tablesConfig';
import validators from 'utils/redux-form/validators';
import { Page, ReduxModalForm, ReduxInputs, Table } from 'components/shared';

import './Cities.scss';

const Cities = (props) => {
    const { actions, cities, filteredCities } = props;
    const { fetchCities, toggleEditModal, submitEdit, edit } = actions;
    const { loading, modals } = cities;

    useEffect(() => {
        fetchCities();
    }, []);

    const getEditFormIntialValues = useCallback((modalPayload) => {
        if (modalPayload.new) {
            return {
                cityName: ''
            };
        }
        return {
            cityName: modalPayload.name
        };
    }, []);

    const openCreateModal = useCallback(() => {
        edit({ new: true });
    }, []);

    const newModalOpen = useMemo(() => !!get(modals, 'editCity.data.new'), [modals]);

    return (
        <Page className="cities-admin wide-table">
            <ReduxModalForm
                formId="adminCitiesEdit"
                title={newModalOpen ? 'Створення міста' : 'Редагування міста'}
                submitButtonText={newModalOpen ? 'Створити' : 'Редагувати'}
                optionsPath="adminCities.modals.editCity"
                getIntialValues={getEditFormIntialValues}
                toggler={toggleEditModal}
                onSubmit={submitEdit}
            >
                <Field
                    name="cityName"
                    component={ReduxInputs.Text}
                    id="city_name"
                    label="Назва міста"
                    validate={[validators.required]}
                />
            </ReduxModalForm>

            <div className="container">
                <Button onClick={openCreateModal} style={{ marginBottom: '20px' }}>Додати місто</Button>
            </div>

            <Table
                rowKey="id"
                rowClassName={record => record.className}
                data={filteredCities}
                loading={loading}
                paginationPath={false}
                columns={adminCitiesColumns}
            />
        </Page>
    );
};

Cities.propTypes = {
    actions: pt.shape({}).isRequired,
    // adminAutomatons: pt.shape({}).isRequired,
    // filteredAdminAutomatons: pt.arrayOf(transaction),
};

Cities.defaultProps = {
    // filteredAdminAutomatons: [],
};

export default Cities;
