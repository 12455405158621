import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Field } from 'redux-form';

import { PAYMENT_TYPES, NULL } from 'app/constants';
import { taxTransactionsColumns } from 'utils/tablesConfig';
import { prepareAutomatonFilter, prepareCashDeskFilter, prepareFopsFilter } from 'utils/by-demand-data-fetch';
import { Page, Filters, ReduxInputs, Table } from 'components/shared';

import './Tax.scss';

const transactionFilterOptions = [PAYMENT_TYPES.cashPayment, PAYMENT_TYPES.cardRefill, PAYMENT_TYPES.cashCollection];

const Tax = (props) => {
    const { actions, taxTransactions, filteredTaxTransactions, automatonFilterValues, fopsFilterValues, cashDesksFilterValues } = props;
    const { fetchTaxTransactions, exportToExcel } = actions;
    const { loading } = taxTransactions;

    useEffect(() => {
        fetchTaxTransactions({ onMount: true });
        prepareAutomatonFilter();
        prepareFopsFilter();
        prepareCashDeskFilter();
    }, []);

    return (
        <Page className="tax wide-table">
            <div className="container">
                <Filters formId="taxTransactionsFilter" initialValues={{ automatons: [], fops: [], cashDesks: [], types: undefined }}>
                    <Row type="flex" justify="space-between">
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="id"
                                id="transactions-tax-id"
                                component={ReduxInputs.Text}
                                inputSpan={24}
                                placeholder="Номер"
                            />
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="automatons"
                                id="transactions-tax-automaton"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="Автомати"
                                mode="multiple"
                            >
                                {automatonFilterValues.map(atmtn => <ReduxInputs.Select.Option key={atmtn.id} value={atmtn.id}>{atmtn.id} -- {atmtn.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="fops"
                                id="transactions-tax-fops"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="ФОП"
                                mode="multiple"
                            >
                                {fopsFilterValues.map(fop => <ReduxInputs.Select.Option key={fop.id} value={fop.id}>{fop.id} -- {fop.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="cashDesks"
                                id="transactions-tax-cash-desks"
                                component={ReduxInputs.Select}
                                inputSpan={24}
                                placeholder="РРО"
                                mode="multiple"
                            >
                                {cashDesksFilterValues.map(cd => <ReduxInputs.Select.Option key={cd.cashRegisterNum} value={cd.cashRegisterNum}>{cd.cashRegisterNum} -- {cd.name}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                        <Col xs={24} sm={{ span: 7 }}>
                            <Field
                                name="transactionTypes"
                                component={ReduxInputs.Select}
                                id="transactions-tax-transactionTypes"
                                placeholder="Тип транзакції"
                                inputSpan={24}
                            >
                                {transactionFilterOptions.map(({ label, value }) => <ReduxInputs.Select.Option key={value} value={value}>{label}</ReduxInputs.Select.Option>)}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Field
                            name="dateRange"
                            component={ReduxInputs.RangePicker}
                            placeholder={['Від', 'До']}
                            id="transactions-tax-taxDateRange"
                            onFocus={e => e.preventDefault()}
                            onBlur={e => e.preventDefault()}
                            inputSpan={24}
                        />
                    </Row>
                    <div className="filter-buttons">
                        <Button className="apply" type="primary" onClick={fetchTaxTransactions}><span>Застосувати фільтри</span></Button>
                        <Button className="export" onClick={exportToExcel}><span>Експорт в Excel</span></Button>
                    </div>
                </Filters>
            </div>

            <Table
                rowKey="id"
                rowClassName={record => record.className}
                data={filteredTaxTransactions}
                loading={loading}
                onFetch={fetchTaxTransactions}
                paginationPath="tax.pagination"
                columns={taxTransactionsColumns}
                horizontal={1920}
            />
        </Page>
    );
};

export default Tax;
