import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute } from 'navigation';

import Automatons from './Automatons';
import Users from './Users';
import Cities from './Cities';
import ServiceCenters from './ServiceCenters';
import Fops from './Fops';
import CashDesks from './CashDesks';
import Cashiers from './Cashiers';

export default () => {
    return (
        <Switch>
            <Route exact path="/admin" render={() => <Redirect to="/admin/automatons" />} />
            <ProtectedRoute path="/admin/automatons" component={Automatons} />
            <ProtectedRoute path="/admin/users" component={Users} />
            <ProtectedRoute path="/admin/cities" component={Cities} />
            <ProtectedRoute path="/admin/service-center" component={ServiceCenters} />
            <ProtectedRoute path="/admin/fops" component={Fops} />
            <ProtectedRoute path="/admin/cash-desks" component={CashDesks} />
            <ProtectedRoute path="/admin/cashiers" component={Cashiers} />
        </Switch>
    );
};
