import React from 'react';

import { getTime } from 'utils/helpers';
import { AutomatonNameCell } from 'components/shared';

export default [
    {
        title: 'ID автомата',
        dataIndex: 'automaton.id',
        key: 'automatonId',
        render: text => <div className="cell">{text}</div>,
    },
    {
        title: 'Назва автомата',
        dataIndex: 'automaton',
        key: 'automaton.name',
        render: automaton => <AutomatonNameCell automaton={automaton} />,
    },
    {
        title: 'Сума, грн',
        dataIndex: 'money',
        render: text => <div className="cell">{text} грн.</div>,
    },
    {
        title: 'Майстер',
        dataIndex: 'user.firstName',
        render: text => <div className="cell">{text || '---'}</div>,
    },
    {
        title: 'Дата',
        dataIndex: 'date',
        render: isoTime => {
            const [date, time] = getTime(isoTime);
            return <div className="cell">{date} {time}</div>;
        }
    },
];
