import { combineReducers } from 'redux';

import balances from './balances/reducer';
import cashCollection from './cash_collection/reducer';
import remainders from './remainders/reducer';
import transactions from './transactions/reducer';
import prices from './prices/reducer';

export default combineReducers({
    remainders,
    balances,
    cashCollection,
    transactions,
    prices,
});
